import { Select as ANTDSelect } from 'antd';
import styled, { css } from 'styled-components';

export const SelectFieldStyle = css`
  border: 0px;
  border-bottom: 1px solid var(--neutral_light);
  box-shadow: 0 0 0 transparent !important;
  font-weight: bold;
`;

export const Select = styled(ANTDSelect)<{
  $tagTheme?: string;
  $selectorStyle?: React.CSSProperties;
}>`
  ${SelectFieldStyle}
  & * {
    box-shadow: 0 0 0 transparent !important;
  }
  & .ant-select-selector {
    border: 0px !important;
    ${(props) => props.$selectorStyle && { ...props.$selectorStyle }}
  }
  & [disabled] {
    cursor: auto !important;
  }
  &.ant-select-focused {
    border-bottom: 1px solid var(--green-sea_medium);
  }
  &.ant-select-disabled,
  &.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: #000 !important;
    background: white !important;
    cursor: auto !important;
    padding: 0px;
    border-bottom: 0px;
    & .ant-select-arrow {
      display: none;
    }
  }
  & .ant-select-selection-item {
    background: var(--neutral_lightest);
  }
  & .ant-select-selection-item-content {
    color: var(--neutral_medium);
  }
  &.ant-select-disabled {
    & .ant-select-selection-item {
      background: transparent;
    }
  }
  & .ant-select-selection-item {
    background: transparent;
  }
  & .ant-select-selection-placeholder {
    font-weight: normal;
  }
  ${(props) =>
    props.$tagTheme === 'neutral' &&
    `
      &.ant-select-multiple .ant-select-selection-item {
        background: var(--neutral_lightest); 
        border-color: var(--neutral_lighter);
        font-weight: 500;
        color: var(--suape_black) !important;
      }

      & .ant-select-selection-item-content {
        color: var(--suape_black);
      }
  `}
`;
