import {
  TableFiveType,
  TableNineType,
  TableOneType,
  TableThreeType,
  TableTwoType,
  TablesToSaveType,
} from '../typesFinancial';
import { tosApi } from './tosApi';
import { getInvalidatesTags } from './utils';

const BASE_URL = '/financial/table';

export const tableFaresApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    createTableOne: build.mutation<TableOneType, TablesToSaveType>({
      query: (table) => ({
        url: `${BASE_URL}/one`,
        method: 'POST',
        body: table,
      }),
      invalidatesTags: (_result, error, queryArgs) =>
        queryArgs.notInvalidateTags
          ? []
          : getInvalidatesTags(error, [
              { type: 'Charge', code: queryArgs.charge_id },
            ]),
    }),
    createTableTwo: build.mutation<TableTwoType, TablesToSaveType>({
      query: (table) => ({
        url: `${BASE_URL}/two`,
        method: 'POST',
        body: table,
      }),
      invalidatesTags: (_result, error, queryArgs) =>
        getInvalidatesTags(error, [
          { type: 'Charge', code: queryArgs.charge_id },
        ]),
    }),
    createTableThree: build.mutation<TableThreeType, TablesToSaveType>({
      query: (table) => ({
        url: `${BASE_URL}/three`,
        method: 'POST',
        body: table,
      }),
      invalidatesTags: (_result, error, queryArgs) =>
        queryArgs.notInvalidateTags
          ? []
          : getInvalidatesTags(error, [
              { type: 'Charge', code: queryArgs.charge_id },
            ]),
    }),
    createTableFive: build.mutation<TableFiveType, TablesToSaveType>({
      query: (table) => ({
        url: `${BASE_URL}/five`,
        method: 'POST',
        body: table,
      }),
      invalidatesTags: (_result, error, queryArgs) =>
        queryArgs.notInvalidateTags
          ? []
          : getInvalidatesTags(error, [
              { type: 'Charge', code: queryArgs.charge_id },
            ]),
    }),
    createTableNine: build.mutation<TableNineType, TablesToSaveType>({
      query: (table) => ({
        url: `${BASE_URL}/nine`,
        method: 'POST',
        body: table,
      }),
      invalidatesTags: (_result, error, queryArgs) =>
        queryArgs.notInvalidateTags
          ? []
          : getInvalidatesTags(error, [
              { type: 'Charge', code: queryArgs.charge_id },
            ]),
    }),
    updateTableOne: build.mutation<TableOneType, TablesToSaveType>({
      query: (table) => ({
        url: `${BASE_URL}/one/${table.id}`,
        method: 'PUT',
        body: table,
      }),
      invalidatesTags: (_result, error, queryArgs) =>
        queryArgs.notInvalidateTags
          ? []
          : getInvalidatesTags(error, [
              { type: 'Charge', code: queryArgs.charge_id },
            ]),
    }),
    updateTableTwo: build.mutation<TableTwoType, TablesToSaveType>({
      query: (table) => ({
        url: `${BASE_URL}/two/${table.id}`,
        method: 'PUT',
        body: table,
      }),
      invalidatesTags: (_result, error, queryArgs) =>
        getInvalidatesTags(error, [
          { type: 'Charge', code: queryArgs.charge_id },
        ]),
    }),
    updateTableThree: build.mutation<TableThreeType, TablesToSaveType>({
      query: (table) => ({
        url: `${BASE_URL}/three/${table.id}`,
        method: 'PUT',
        body: table,
      }),
      invalidatesTags: (_result, error, queryArgs) =>
        queryArgs.notInvalidateTags
          ? []
          : getInvalidatesTags(error, [
              { type: 'Charge', code: queryArgs.charge_id },
            ]),
    }),
    updateTableFive: build.mutation<TableFiveType, TablesToSaveType>({
      query: (table) => ({
        url: `${BASE_URL}/five/${table.id}`,
        method: 'PUT',
        body: table,
      }),
      invalidatesTags: (_result, error, queryArgs) =>
        queryArgs.notInvalidateTags
          ? []
          : getInvalidatesTags(error, [
              { type: 'Charge', code: queryArgs.charge_id },
            ]),
    }),
    updateTableNine: build.mutation<TableNineType, TablesToSaveType>({
      query: (table) => ({
        url: `${BASE_URL}/nine/${table.id}`,
        method: 'PUT',
        body: table,
      }),
      invalidatesTags: (_result, error, queryArgs) =>
        queryArgs.notInvalidateTags
          ? []
          : getInvalidatesTags(error, [
              { type: 'Charge', code: queryArgs.charge_id },
            ]),
    }),
    deleteTableOne: build.mutation<void, { chargeId: number; tableId: number }>(
      {
        query: (table) => ({
          url: `${BASE_URL}/one/${table.tableId}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_result, error, queryArgs) =>
          getInvalidatesTags(error, [
            { type: 'Charge', code: queryArgs.chargeId },
          ]),
      }
    ),
    deleteTableTwo: build.mutation<void, { chargeId: number; tableId: number }>(
      {
        query: (table) => ({
          url: `${BASE_URL}/two/${table.tableId}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_result, error, queryArgs) =>
          getInvalidatesTags(error, [
            { type: 'Charge', code: queryArgs.chargeId },
          ]),
      }
    ),
    deleteTableThree: build.mutation<
      void,
      { chargeId: number; tableId: number }
    >({
      query: (table) => ({
        url: `${BASE_URL}/three/${table.tableId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error, queryArgs) =>
        getInvalidatesTags(error, [
          { type: 'Charge', code: queryArgs.chargeId },
        ]),
    }),
    deleteTableFive: build.mutation<
      void,
      { chargeId: number; tableId: number }
    >({
      query: (table) => ({
        url: `${BASE_URL}/five/${table.tableId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error, queryArgs) =>
        getInvalidatesTags(error, [
          { type: 'Charge', code: queryArgs.chargeId },
        ]),
    }),
    deleteTableNine: build.mutation<
      void,
      { chargeId: number; tableId: number }
    >({
      query: (table) => ({
        url: `${BASE_URL}/nine/${table.tableId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error, queryArgs) =>
        getInvalidatesTags(error, [
          { type: 'Charge', code: queryArgs.chargeId },
        ]),
    }),
  }),
  overrideExisting: true,
});

export const {
  useCreateTableOneMutation,
  useCreateTableTwoMutation,
  useCreateTableThreeMutation,
  useCreateTableFiveMutation,
  useCreateTableNineMutation,
  useUpdateTableOneMutation,
  useUpdateTableTwoMutation,
  useUpdateTableThreeMutation,
  useUpdateTableFiveMutation,
  useUpdateTableNineMutation,
  useDeleteTableOneMutation,
  useDeleteTableTwoMutation,
  useDeleteTableThreeMutation,
  useDeleteTableFiveMutation,
  useDeleteTableNineMutation,
} = tableFaresApi;
