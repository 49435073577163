import { isEmpty } from 'lodash';

import { CompanyType } from '../../../types';
import {
  AllTableItemsToSaveType,
  AllTableItemsType,
  ChargeToFormType,
  ChargeToSaveType,
  FareToSaveType,
  FareType,
  // PriceTableType,
} from '../../../typesFinancial';
import { createDateString, isNullOrUndefined } from '../../../utils/utils';

export function formatChargeToSave(
  charge: ChargeToFormType
  // priceTables?: PriceTableType[]
): ChargeToSaveType {
  return {
    charge_type: charge.charge_type,
    stopover: charge.stopover?.id,
    docking: charge.docking?.id,
    vessel: charge.vessel?.id,
    company: charge.company?.id,
    nature_of_cargo: charge.nature_of_cargo?.id,
    product_group: charge.product_group?.id,
    fare: formatFaresToSave(charge.company, charge.tables),
  } as ChargeToSaveType;
}

export function formatFaresToSave(
  chargeCompany: CompanyType,
  tables?: FareType[]
  // priceTables?: PriceTableType[]
): FareToSaveType[] {
  const faresArray: FareToSaveType[] = [];
  faresArray[0] = {} as FareToSaveType;
  tables?.forEach((table) => {
    if (!isEmpty(table) && table.table_type) {
      faresArray[0][table.table_type] = {
        ...table,
        items: formatTableItemsToSave(table.items || []),
      };
    }
    faresArray[0].company = chargeCompany?.id;
  });
  return faresArray;
}

export function formatTableItemsToSave(
  tableItems: AllTableItemsType[]
  // priceTables?: PriceTableType[]
): AllTableItemsToSaveType[] {
  return (
    tableItems.map((item) => ({
      id: item.id,
      name: item.name,
      observations: item.observations,
      value: item.value,
      tax: item.tax,
      navigation_type: item.navigation_type?.id,
      operation_type: item.operation_type?.id,
      dwt: item.dwt,
      docking_place: item.docking_place?.tag,
      loa: item.loa,
      fraction_of_minutes_after_limit: item.fraction_of_minutes_after_limit,
      fraction_of_minutes_before_limit: item.fraction_of_minutes_before_limit,
      cargo_type: item.cargo_type?.name,
      movimented_cargo: item.movimented_cargo,
      product_group:
        item.product_group?.map((productGroup) =>
          typeof productGroup === 'number' ? productGroup : productGroup.id || 0
        ) || [],
      quantity: item.quantity,
      number: item.number,
      cargo_arrival_date: item.cargo_arrival_date
        ? createDateString(item.cargo_arrival_date)
        : null,
      cargo_departure_date: item.cargo_departure_date
        ? createDateString(item.cargo_departure_date)
        : null,
      complement_type: item.complement_type?.id,
      childs:
        item.childs
          ?.filter((item) => !isNullOrUndefined(item.id))
          ?.map((child) => child.id) || [],
      fee: item.fee,
      segregated_billing: item.segregated_billing,
      code: item.code,
    })) || []
  );
}
