import { Row, FormInstance, Alert } from 'antd';
import moment, { Duration } from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BaseSelectRef } from 'rc-select';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import {
  FormItemsOperation,
  Collapse,
  FormItem,
  FormItemSelect,
  FormItemDatePicker,
  Title,
  Descriptions,
  FormatItem,
} from '../../components';
import { useGetSystemParamByNameQuery } from '../../services/systemParamsApi';
import { useGetVesselsQuery } from '../../services/vesselApi';
import { Docking as DockingType, DockingPlace, Vessel } from '../../types';
import {
  dockingAlongsideList,
  dockingInLineList,
  positionDockingList,
  supportVesselList,
} from '../../utils/lists';
import { isNullOrUndefined, createDateStringPtBr } from '../../utils/utils';

const { Panel } = Collapse;
const { Item } = Descriptions;

type FormItemsPredictionsProps = {
  form: FormInstance;
  readOnly: boolean;
  onSelectBerth: (value: string) => void;
  allowedBerth: any;
  allowedCargo: any;
  allowedOperators: any;
  expectedArrival: moment.Moment | null;
  expected_unberthing_last_docking: moment.Moment | null;
  pilot_expected_on_board_next_docking: moment.Moment | null;
  berthData?: {
    results: any[];
  };
  isLoadingBerthData: boolean;
  screen: 'STOPOVER' | 'AGENT_AREA';
  selectedDocking: DockingType;
  openCollapsePanels: string | string[];
  setOpenCollapsePanels: Dispatch<SetStateAction<string | string[]>>;
};

export function FormItemsPredictions(props: FormItemsPredictionsProps) {
  const {
    form,
    readOnly,
    onSelectBerth,
    allowedBerth,
    allowedCargo,
    allowedOperators,
    expectedArrival,
    expected_unberthing_last_docking,
    pilot_expected_on_board_next_docking,
    berthData,
    isLoadingBerthData,
    screen,
    selectedDocking,
    openCollapsePanels,
    setOpenCollapsePanels,
  } = props;
  const berthFieldRef = useRef<BaseSelectRef>(null);
  const berthFieldRowRef = useRef<HTMLDivElement>(null);

  const [allowAddCargo, setAllowAddCargo] = useState(true);
  const [selectedDockingGoalCode, setSelectedDockingGoalCode] = useState('');
  const [supportVesselType, setSupportVesselType] = useState('');
  const [convoyVesselName, setConvoyVesselName] = useState('');
  const [convoySelectDisabled, setConvoySelectDisabled] = useState(true);

  const [berthingDuration, setBerthingDuration] = useState<Duration | null>(
    null
  );

  const { data: dockingGoalsData } = useGetSystemParamByNameQuery({
    name: 'DOCKING_GOAL',
  });

  const { data: vesselData, isFetching: isFetchingVesselData } =
    useGetVesselsQuery({ page_size: 100, imo_or_name: convoyVesselName });

  function onClearBerth() {
    setAllowAddCargo(false);
  }

  function handleSelectBerth(berthTag: string) {
    onSelectBerth(berthTag);
    const selectedDockingPlaceObj = allowedBerth.find(
      (berth: DockingPlace) => berth.tag === berthTag
    );
    if (selectedDockingPlaceObj) {
      form.setFieldsValue({
        docking_place: {
          max_draught: selectedDockingPlaceObj.max_draught,
        },
      });
    }
    setAllowAddCargo(true);
  }

  function handleSelectSupportVesselType(option: string) {
    if (option === 'NO') {
      setConvoySelectDisabled(true);

      if (form.getFieldValue(['convoy_vessel'])) {
        form.setFieldsValue({ convoy_vessel: null });
      }
    } else {
      setConvoySelectDisabled(false);
    }

    setSupportVesselType(option);
  }

  useEffect(() => {
    if (isNullOrUndefined(form.getFieldValue(['docking_place', 'tag']))) {
      setAllowAddCargo(false);
    } else {
      const objectDocking = {
        key: form.getFieldValue(['docking_place', 'tag']),
        value: form.getFieldValue(['docking_place', 'tag']),
      };
      handleSelectBerth(form.getFieldValue(['docking_place', 'tag']));
    }
  });

  useEffect(() => {
    if (readOnly || selectedDocking.is_support_vessel === 'NO') {
      setConvoySelectDisabled(true);
    } else {
      setConvoySelectDisabled(false);
    }

    if (selectedDocking.convoy_vessel?.name) {
      setConvoyVesselName(selectedDocking.convoy_vessel.name);
    }
  }, [selectedDocking, readOnly]);

  function onChangeRangePicker(value: any, field: 'berthing' | 'pilot') {
    const update =
      field === 'berthing'
        ? {
            expected_berthing: value ? value[0] || undefined : undefined,
            expected_unberthing: value ? value[1] || undefined : undefined,
          }
        : field === 'pilot'
        ? {
            pilot_expected_on_board: value ? value[0] || undefined : undefined,
            pilot_expected_leaving_on_board: value
              ? value[1] || undefined
              : undefined,
          }
        : {};
    if (field === 'berthing' && value && value[1] && value[0]) {
      setBerthingDuration(moment.duration(value[1].diff(value[0])));
    } else {
      setBerthingDuration(null);
    }
    form.setFieldsValue(update);
  }

  useEffect(() => {
    const expected_berthing = form.getFieldValue(['berthing', 0]);
    const expected_unberthing = form.getFieldValue(['berthing', 1]);
    if (expected_berthing && expected_unberthing) {
      setBerthingDuration(
        moment.duration(expected_unberthing.diff(expected_berthing))
      );
    } else {
      setBerthingDuration(null);
    }
  }, [form.getFieldValue(['berthing'])]);

  function panelHeaderTitle(title: string) {
    const date = form.getFieldValue('updated_at');

    return (
      <>
        {title}
        {!isNullOrUndefined(date) && (
          <span
            className="header-description"
            style={{ float: 'right', fontWeight: 'normal', fontSize: '13px' }}
          >
            Atualizado em: {moment(date).format('DD/MM/YYYY HH:mm')}
          </span>
        )}
      </>
    );
  }

  function onSelectDockingGoal(value: any) {
    const selectedDockingGoal = dockingGoalsData?.items.find(
      (goal) => goal.id === value
    );
    if (selectedDockingGoal) {
      setSelectedDockingGoalCode(selectedDockingGoal.code);
    }
  }

  function getBerthData() {
    if (
      selectedDockingGoalCode &&
      selectedDockingGoalCode !== 'CARGO_HANDLING'
    ) {
      return berthData?.results;
    }
    return allowedBerth || [];
  }

  function onChangePilotOnBoard(
    selectedDate: any,
    manoeuvre: 'DOCKING' | 'UNDOCKING'
  ) {
    if (selectedDate) {
      if (manoeuvre === 'DOCKING') {
        props.form.setFieldsValue({
          pilot_expected_leaving_on_board: moment(selectedDate).add({
            hours: 1,
            minutes: 15,
          }),
          expected_berthing: moment(selectedDate).add(1, 'hour'),
        });
      } else if (manoeuvre === 'UNDOCKING') {
        props.form.setFieldsValue({
          pilot_expected_leaving_on_board_undocking: moment(selectedDate).add({
            hours: 1,
            minutes: 15,
          }),
          expected_unberthing: moment(selectedDate).add(1, 'hour'),
        });
      }
    }
  }

  function onChangePilotOnBoardExpectedBerthing(
    selectedDate: any,
    manoeuvre: 'DOCKING' | 'UNDOCKING'
  ) {
    if (selectedDate) {
      if (manoeuvre === 'DOCKING') {
        props.form.setFieldsValue({
          pilot_expected_leaving_on_board: moment(selectedDate).add({
            minutes: 15,
          }),
          pilot_expected_on_board: moment(selectedDate).subtract(1, 'hour'),
        });
      } else if (manoeuvre === 'UNDOCKING') {
        props.form.setFieldsValue({
          pilot_expected_leaving_on_board_undocking: moment(selectedDate).add({
            minutes: 15,
          }),
          pilot_expected_on_board_undocking: moment(selectedDate).subtract(
            1,
            'hour'
          ),
        });
      }
    }
  }

  function formatDockingUserTracking(
    last_user: boolean | undefined,
    last_date: string | undefined
  ): string {
    if (isNullOrUndefined(last_date)) {
      return '';
    }
    return `Atualizado ${
      last_user ? 'por Suape' : 'pelo Agente'
    } em ${createDateStringPtBr(last_date)}`;
  }

  function getDockingResumeDescription(): JSX.Element {
    return (
      <Descriptions column={2}>
        <Item label="Berço">
          <FormatItem>{selectedDocking?.docking_place?.name}</FormatItem>
        </Item>
        <Item label="Posição de atracação">
          <FormatItem>
            {
              positionDockingList.find(
                (item) => item.id === selectedDocking?.position
              )?.name
            }
          </FormatItem>
        </Item>
        <Item label="Previsão de atracação">
          <FormatItem>
            {createDateStringPtBr(selectedDocking?.expected_berthing)}
          </FormatItem>
        </Item>
        <Item label="Previsão de desatracação">
          <FormatItem>
            {createDateStringPtBr(selectedDocking?.expected_unberthing)}
          </FormatItem>
        </Item>
        <Item label="Duração da estadia">
          <FormatItem>{null}</FormatItem>
        </Item>
      </Descriptions>
    );
  }

  return (
    <>
      {/* <FormItem noStyle name={['index']}></FormItem> */}
      <FormItem noStyle name={['id']} />
      <FormItem name={['code']} noStyle />
      {screen === 'STOPOVER' && (
        <>
          <Row
            align="top"
            style={{
              paddingLeft: '16px',
              paddingRight: '16px',
              paddingTop: '16px',
            }}
          >
            <FormItemSelect
              colSpan={12}
              name={['docking_goal', 'id']}
              label="Finalidade da atracação"
              dataList={dockingGoalsData?.items
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))}
              disabled={readOnly}
              required
              onSelect={onSelectDockingGoal}
            />
          </Row>
          <Collapse
            expandIconPosition="end"
            activeKey={openCollapsePanels}
            onChange={setOpenCollapsePanels}
          >
            <Panel
              forceRender
              header={panelHeaderTitle('Operações')}
              key="operation"
            >
              <FormItemsOperation
                readOnly={readOnly}
                form={form}
                allowAddCargo={allowAddCargo}
                berthFieldRef={berthFieldRef}
                berthFieldRowRef={berthFieldRowRef}
                allowedCargo={allowedCargo}
                allowedOperators={allowedOperators}
              />
            </Panel>
          </Collapse>
        </>
      )}
      {screen === 'AGENT_AREA' && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '24px 29px',
          }}
        >
          {isNullOrUndefined(selectedDocking?.code) && (
            <Alert
              message="O código da atracação só será gerado a partir do registro do primeiro cabo amarrado."
              type="info"
              style={{ marginBlockEnd: '16px' }}
            />
          )}
          <Alert message={getDockingResumeDescription()} type="info" />
        </div>
      )}
      <Collapse
        expandIconPosition="end"
        activeKey={openCollapsePanels}
        onChange={setOpenCollapsePanels}
      >
        <Panel
          forceRender
          header={panelHeaderTitle('Dados gerais')}
          key="generalData"
        >
          <Row gutter={24} align="top">
            {screen === 'STOPOVER' && (
              <>
                <FormItem name={['docking_place', 'max_draught']} noStyle />
                <FormItemSelect
                  colSpan={12}
                  name={['docking_place', 'tag']}
                  label="Berço / Local de ancoragem"
                  notFoundContent="Selecione uma embarcação e a finalidade da estadia primeiro"
                  onClear={onClearBerth}
                  disabled={readOnly}
                  onSelect={handleSelectBerth}
                  showSearch
                  allowClear={isNullOrUndefined(
                    selectedDocking.docking_manoeuvre?.pilot_on_board
                  )}
                  dataList={getBerthData()?.filter(
                    (dockingPlace: any) => dockingPlace.place_type === 'BERTH'
                  )}
                  isLoading={isLoadingBerthData}
                />
              </>
            )}
            {screen === 'AGENT_AREA' && (
              <FormItemSelect
                colSpan={12}
                name={['docking_goal', 'id']}
                label="Finalidade da atracação"
                dataList={dockingGoalsData?.items
                  .slice()
                  .sort((a, b) => a.name.localeCompare(b.name))}
                disabled={readOnly}
                required
                onSelect={onSelectDockingGoal}
                help={formatDockingUserTracking(
                  selectedDocking?.docking_user_tracking
                    ?.docking_goal_last_user,
                  selectedDocking?.docking_user_tracking?.docking_goal_last_date
                )}
              />
            )}
            <FormItemSelect
              colSpan={12}
              name="position"
              label="Posição da atracação"
              dataList={positionDockingList}
              disabled={readOnly}
              help={formatDockingUserTracking(
                selectedDocking?.docking_user_tracking?.position_last_user,
                selectedDocking?.docking_user_tracking?.position_last_date
              )}
            />
          </Row>
          <Row gutter={24} align="top">
            <FormItemSelect
              colSpan={12}
              name="docking_in_line"
              label="Atracação em linha?"
              tooltip="Dois navios no mesmo berço"
              dataList={dockingInLineList}
              disabled={readOnly}
            />
            <FormItemSelect
              colSpan={12}
              name="docking_alongside"
              label="Atracação à contrabordo?"
              dataList={dockingAlongsideList}
            />
          </Row>
          <Row gutter={24} align="top">
            <FormItemSelect
              colSpan={12}
              name="is_support_vessel"
              label="É embarcação de apoio?"
              tooltip="Embarcações não comerciais que servirão de apoio marítimo offshore, apoio portuário ou comboio (rebocador + balsa)."
              dataList={supportVesselList}
              onSelect={handleSelectSupportVesselType}
              disabled={readOnly}
            />
            <FormItemSelect
              colSpan={12}
              name={['convoy_vessel', 'id']}
              label="Embarcação de comboio"
              onSearch={setConvoyVesselName}
              dataList={vesselData?.results}
              disabled={convoySelectDisabled}
              onClear={() => setConvoyVesselName('')}
              showSearch
              allowClear
              isLoading={isFetchingVesselData}
            />
          </Row>
          <Title>ATRACAÇÃO</Title>
          <Row gutter={24} align="top">
            <FormItemDatePicker
              colSpan={8}
              label="Previsão de prático a bordo"
              name="pilot_expected_on_board"
              minuteStep={15}
              onChange={(date) => onChangePilotOnBoard(date, 'DOCKING')}
              help={formatDockingUserTracking(
                selectedDocking?.docking_user_tracking
                  ?.pilot_expected_on_board_last_user,
                selectedDocking?.docking_user_tracking
                  ?.pilot_expected_on_board_last_date
              )}
            />
            <FormItemDatePicker
              colSpan={8}
              label="Previsão de saída do prático a bordo"
              name="pilot_expected_leaving_on_board"
              minuteStep={15}
              help={formatDockingUserTracking(
                selectedDocking?.docking_user_tracking
                  ?.pilot_expected_leaving_on_board_last_user,
                selectedDocking?.docking_user_tracking
                  ?.pilot_expected_leaving_on_board_last_date
              )}
            />
            <FormItemDatePicker
              colSpan={8}
              label="Previsão de atracação"
              name="expected_berthing"
              minuteStep={15}
              tooltip="Início da atracação"
              onChange={(date) =>
                onChangePilotOnBoardExpectedBerthing(date, 'DOCKING')
              }
              help={formatDockingUserTracking(
                selectedDocking?.docking_user_tracking
                  ?.expected_berthing_last_user,
                selectedDocking?.docking_user_tracking
                  ?.expected_berthing_last_date
              )}
            />
          </Row>
          <Title>DESATRACAÇÃO</Title>
          <Row gutter={24} align="top">
            <FormItemDatePicker
              colSpan={8}
              label="Previsão de prático a bordo"
              name="pilot_expected_on_board_undocking"
              minuteStep={15}
              onChange={(date) => onChangePilotOnBoard(date, 'UNDOCKING')}
              help={formatDockingUserTracking(
                selectedDocking?.docking_user_tracking
                  ?.pilot_expected_on_board_undocking_last_user,
                selectedDocking?.docking_user_tracking
                  ?.pilot_expected_on_board_undocking_last_date
              )}
            />
            <FormItemDatePicker
              colSpan={8}
              label="Previsão de saída do prático a bordo"
              name="pilot_expected_leaving_on_board_undocking"
              minuteStep={15}
              help={formatDockingUserTracking(
                selectedDocking?.docking_user_tracking
                  ?.pilot_expected_leaving_on_board_undocking_last_user,
                selectedDocking?.docking_user_tracking
                  ?.pilot_expected_leaving_on_board_undocking_last_date
              )}
            />
            <FormItemDatePicker
              colSpan={8}
              label="Previsão de desatracação"
              name="expected_unberthing"
              minuteStep={15}
              tooltip="Fim da atracação"
              onChange={(date) =>
                onChangePilotOnBoardExpectedBerthing(date, 'UNDOCKING')
              }
              help={formatDockingUserTracking(
                selectedDocking?.docking_user_tracking
                  ?.expected_unberthing_last_user,
                selectedDocking?.docking_user_tracking
                  ?.expected_unberthing_last_date
              )}
            />
          </Row>
        </Panel>
      </Collapse>
    </>
  );
}
