import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Row } from 'antd';
import { useState } from 'react';

import {
  Button,
  Form,
  FormItemDatePicker,
  Modal,
  ScreenLayout,
  TabPane,
  Tabs,
} from '../../components';
import {
  ReportsQueryParamsType,
  useLazyExportWebsiteQuery,
  useLazyExportBIQuery,
  useLazyExportPortDockingCargoReportQuery,
} from '../../services/stopoverApi';
import { createDateString, downloadFile } from '../../utils/utils';
import { BreakdownsSection } from './breakdownsSection/BreakdownsSection';
import { DockingsSection } from './dockingsSection/DockingsSection';
import { OperationsSection } from './operationsSection/OperationsSection';
import { VesselsSection } from './vesselsSection/VesselsSection';
import { WarehousingVehiclesSection } from './warehousingVehiclesSection/WarehousingVehiclesSection';

export type TablePageProps = {
  dataSource: Record<string, any>[];
  scrollOptions?: Record<string, any>;
  total?: number;
  itemsPerPage?: number;
  isLoading?: boolean;
  pageSize?: number;
  showSizeChanger?: boolean;
  rowKey?: string;
  onChangePagination?: (page: number) => number;
  queryPage: number;
};

export function Reports() {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<{
    key: string;
    label: string;
  } | null>(null);

  function onClickItem(key: any) {
    setSelectedItem(items?.find((item) => item?.key === key.key) || null);
    setIsVisibleModal(true);
  }

  const items = [
    {
      key: 'bi',
      label: 'Levantamento BI',
      onClick: (key: any) => {
        onClickItem(key);
      },
    },
    {
      key: 'port',
      label: 'Relatório porto, cargas e atracações',
      onClick: (key: any) => {
        onClickItem(key);
      },
    },
  ];

  const [form] = Form.useForm();

  const [exportWebsite] = useLazyExportWebsiteQuery();
  const [exportBI] = useLazyExportBIQuery();
  const [exportPort] = useLazyExportPortDockingCargoReportQuery();

  async function onGetReport() {
    const dates: ReportsQueryParamsType = {
      initial_date: null,
      final_date: null,
    };
    form.validateFields().then(async (values) => {
      if (
        values &&
        values.range &&
        values.range.length > 0 &&
        values.range[0]
      ) {
        dates.initial_date = createDateString(values.range[0], true);
      }
      if (
        values &&
        values.range &&
        values.range.length > 1 &&
        values.range[1]
      ) {
        dates.final_date = createDateString(values.range[1], true);
      }
      if (selectedItem?.key === 'website') {
        await exportWebsite(dates).then((response) => {
          handleDownload(response, 'website.xlsx');
        });
      }
      if (selectedItem?.key === 'bi') {
        await exportBI(dates);
        setIsVisibleModal(false);
      }
      if (selectedItem?.key === 'port') {
        await exportPort(dates).then((response) => {
          setIsVisibleModal(false);
        });
      }
    });
  }

  function handleDownload(response: any, fileName: string) {
    if ('data' in response) {
      downloadFile(response.data, fileName);
      setIsVisibleModal(false);
    }
  }

  return (
    <ScreenLayout
      title="Relatórios"
      extra={
        <Dropdown
          placement="bottomRight"
          arrow
          overlay={<Menu items={items} />}
        >
          <Button style={{ marginRight: '10px' }}>
            Baixar relatório gerencial
            <DownOutlined />
          </Button>
        </Dropdown>
      }
    >
      <Modal
        className="TOSPrimaryModal"
        title="Baixar relatório gerencial"
        visible={isVisibleModal}
        okButtonProps={{ htmlType: 'submit', form: 'select_range' }}
        onCancel={() => {
          setIsVisibleModal(false);
          setSelectedItem(null);
        }}
        okText="Baixar"
      >
        <Form
          layout="vertical"
          form={form}
          name="select_range"
          id="select_range"
          onFinish={onGetReport}
        >
          <Row>
            Preencha o período abaixo para baixar o relatório &#34;
            {selectedItem?.label}&#34;:
            <FormItemDatePicker
              label="Período de início de escala"
              name="range"
              isRangePicker
              style={{ marginTop: '10px' }}
            />
          </Row>
        </Form>
      </Modal>
      <Tabs
        defaultActiveKey=""
        size="small"
        tabPosition="top"
        nobackground="true"
      >
        <TabPane tab="Atracação" key="dockings">
          <DockingsSection />
        </TabPane>
        <TabPane tab="Operações" key="operations">
          <OperationsSection />
        </TabPane>
        <TabPane tab="Avarias e ocorrências" key="occurences">
          <BreakdownsSection />
        </TabPane>
        <TabPane tab="Movimentação de embarcações" key="movement_of_vessels">
          <VesselsSection />
        </TabPane>
        <TabPane tab="Armazenamento de veículos" key="warehousing_vehicles">
          <WarehousingVehiclesSection />
        </TabPane>
      </Tabs>
    </ScreenLayout>
  );
}
