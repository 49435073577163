import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  :root {
    //green sea
    --green-sea_medium : #09D4AB;
    --green-sea_dark : #1AAA8C;
    --green-sea_light : #04EDBE;
    --green-sea_lighter : #8EF8E2;
    //marine
    --marine_medium : #25BFD5;
    --marine_dark : #0096AB;
    --marine_light : #A0E6F1;
    --marine_lighter : #CFF2F8;
    //ocean
    --ocean_medium : #2080AD;
    --ocean_dark : #1D6181;
    --ocean_light : #8FBFD6;
    --ocean_lighter : #C5E0E9;
    //neutral
    --neutral_medium : #5F7B91;
    --neutral_dark : #2E3439;
    --neutral_light : #A0BACE;
    --neutral_lighter : #CCDDE9;
    --neutral_lightest : #F2F6F9;

    //anchoring window
    --delayed-docking : #FFC069;

    //feedback colors
    //info
    --info-medium : #25BFD5;
    --info-light : #A0E6F1;
    --info-dark : #0096AB;
    --info-lighter : #CFF2F8;
    //success
    --success-medium : #54D25D;
    --success-light : #90F097;
    --success-dark : #1AAA24;
    --success-lighter : #C8FACB;
    --success-lightest : #E9FDEA;
    //warning
    --warning-medium : #FCCC0A;
    --warning-light : #FFE88C;
    --warning-dark : #D46B08;
    --warning-lighter : #FFE7BA;
    --warning-lightest : #FFF8EA;
    //error
    --error-medium : #E65050;
    --error-light : #F0ADAD;
    --error-dark : #8F161E;
    --error-lighter : #F8E2E2;
    --error-lightest : #FCF3F3;

    //gradients
    --gradient-1 : linear-gradient(90deg, @marine_medium 0%, @green-sea_dark 100%);
    --gradient-2 : linear-gradient(90deg, @green-sea_light 0%, @green-sea_dark 100%);
    --gradient-3 : linear-gradient(90deg, @ocean_medium 0%, @green-sea_dark 100%);

    --brand-deep-green : #003804;

    --suape-black : rgba(0, 0, 0, 0.85);
  }  
`;
