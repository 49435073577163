import { EyeFilled } from '@ant-design/icons';
import { Col, Row, Tag, Tooltip, Typography } from 'antd';
import moment from 'moment';
import Flag from 'react-flagpack';
import styled, { ThemeProvider } from 'styled-components';

import { Button } from '../../components/antd/Button';
import ErrorBoundary from '../../components/common/ErrorBoundary';
import { ManoeuvreBoardType, Vessel } from '../../types';
import { translateManoeuvreStatusName } from '../../utils/utils';
// import { FormItemsStaymentSummary } from './formItensStaymentSummary';

// Caso seja necessaŕio realinhamento das colunas, alterar aqui
export const statusLineWidth = '20px'; // Largura do espaço entre as colunas de data e situação
export const contentInnerPadding = '10px'; // Padding de dentro da celula (área branca com conteúdo)
export const contentOuterPadding = '10px'; // Padding entre a celula e a div exterior
export const largeCol = {
  xl: 6,
  lg: 6,
};
export const midLargeCol = {
  xl: 5,
  lg: 5,
};
export const midCol = {
  xl: 4,
  lg: 4,
};
export const midSmallCol = {
  xl: 3,
  lg: 3,
};
export const smallCol = {
  xl: 2,
  lg: 2,
};

type ManoeuvreListItemProps = {
  manoeuvre: ManoeuvreBoardType;
  onOpenManoeuvre: (manoeuvre: ManoeuvreBoardType) => void;
  onOpenPilotExpectForm: (manoeuvre: ManoeuvreBoardType) => void;
  canOpenManoeuvre: boolean;
};

const sharedContentStyles = `
  background: #f2f6f9;
  border-radius: 6px;
  height: 100%;
  padding: ${contentInnerPadding};
  display: flex;
  align-items: center;
`;

const ManoeuvreItem = styled.div`
  position: relative;
  display: flex;

  & .maneuver-date,
  & > .maneuver-infos {
    padding: 17px ${contentOuterPadding} 0 ${contentOuterPadding};
    min-height: 70px;

    & > .content {
      ${sharedContentStyles}
    }
  }

  & .prediction-content {
    cursor: pointer;
    ${sharedContentStyles}
  }

  & .prediction-content:hover {
    background: #fff;
  }

  & > .maneuver-infos {
    width: 88%;
  }

  & > .maneuver-date {
    width: 12%;
  }

  &:first-child .status-line {
    height: 50%;
    margin-top: auto;
  }

  &:last-child .status-line {
    height: 50%;
    margin-bottom: auto;
  }

  & .vessel-flag {
    margin-right: 10px;
  }
`;

const StatusLineStyle = styled.div`
  width: ${statusLineWidth};
  top: 0px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  & > .status-line {
    width: 1.7px;
    height: 100%;
    background: var(--neutral_lighter);
  }
  & > .status {
    width: 15px;
    height: 15px;
    background: ${(props) => props.theme.background};
    border-radius: 100%;
    position: absolute;
    border: 1.7px solid var(--neutral_lighter);
    margin-top: 10px;
    cursor: pointer;
  }
`;

const { Paragraph } = Typography;

export function ManoeuvreListItem(props: ManoeuvreListItemProps) {
  const paragraphConfig = {
    style: {
      marginBottom: '0px',
    },
    ellipsis: {
      rows: 3,
    },
  };

  const vesselConfig = {
    style: {
      fontWeight: 'bold' as const,
      width: '100%',
      marginBottom: '0px',
    },
    ellipsis: {
      rows: 3,
    },
  };

  function createVesselTooltip(vessel: Vessel) {
    return (
      <>
        <b>{vessel?.name}</b> <br />
        Cabo: {vessel?.dock_line_type} <br />
        DWT: {vessel?.dwt} <br />
        Boca: <br />
        LOA: {vessel?.loa} <br />
        Calado: <br />
      </>
    );
  }

  return (
    <ManoeuvreItem>
      <div
        className="maneuver-date"
        onClick={() => props.onOpenPilotExpectForm(props.manoeuvre)}
      >
        <div className="prediction-content">
          {props.manoeuvre?.docking_purpose === 'ANCHORING_SERVICES' && (
            <div style={{ textAlign: 'center', width: '100%' }}>
              <b>Não se aplica</b>
            </div>
          )}
          <Tooltip placement="bottomLeft" title="Previsão de prático a bordo">
            {props.manoeuvre?.docking_purpose !== 'ANCHORING_SERVICES' && (
              <div style={{ textAlign: 'center', width: '100%' }}>
                {props.manoeuvre?.pilot_expected_on_board_or_undocking ? (
                  <>
                    {moment(
                      props.manoeuvre?.pilot_expected_on_board_or_undocking
                    ).format('DD/MM/yyyy')}
                    <br />
                    <b>
                      {' '}
                      {moment(
                        props.manoeuvre?.pilot_expected_on_board_or_undocking
                      ).format('HH:mm')}
                    </b>
                  </>
                ) : (
                  'Não informado'
                )}
              </div>
            )}
          </Tooltip>
        </div>
      </div>
      <StatusLine status={props.manoeuvre.status} />
      <div className="maneuver-infos">
        <div className="content">
          <Row gutter={12} style={{ width: '100%' }} align="middle">
            <Col {...midSmallCol}>
              <Tooltip
                placement="bottomLeft"
                title={createVesselTooltip(props.manoeuvre?.vessel)}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ErrorBoundary replace="">
                    {props.manoeuvre?.vessel.flag && (
                      <Flag
                        code={props.manoeuvre?.vessel.flag}
                        size="l"
                        className="vessel-flag"
                      />
                    )}
                  </ErrorBoundary>
                  <Paragraph {...vesselConfig}>
                    {props.manoeuvre?.vessel.name}
                  </Paragraph>
                  {/* <Tooltip placement="top" title="Embarcação com restrições">
                    <WarningFilled
                      style={{
                        color: '#fa8c16',
                        fontSize: '18px',
                        marginLeft: '10px',
                      }}
                    />
                  </Tooltip> */}
                </div>
              </Tooltip>
            </Col>
            <Col {...midSmallCol}>
              <Paragraph {...paragraphConfig}>
                {props.manoeuvre?.shipping_agency?.name}
              </Paragraph>
            </Col>
            <Col {...midCol}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Paragraph {...paragraphConfig}>
                  {props.manoeuvre.pilotage_company?.name || '-'}
                </Paragraph>
                {/* <Tooltip
                  placement="bottom"
                  title="Empresa com duas manobras num mesmo dia / horário"
                >
                  <WarningFilled
                    style={{
                      color: '#fa8c16',
                      fontSize: '18px',
                    }}
                  />
                </Tooltip> */}
              </div>
            </Col>
            <Col {...midCol}>
              <Paragraph {...paragraphConfig}>
                {props.manoeuvre.towage_company?.name || '-'}
              </Paragraph>
            </Col>
            <Col {...smallCol}>
              <Tooltip
                placement="bottomLeft"
                title={props.manoeuvre?.docking_position}
              >
                {props.manoeuvre.origin?.tag}
              </Tooltip>
            </Col>
            <Col {...smallCol}>
              <Tooltip
                placement="bottomLeft"
                title={props.manoeuvre?.docking_position}
              >
                {props.manoeuvre.destiny?.tag}
              </Tooltip>
            </Col>
            <Col {...midLargeCol}>
              <DockingTypeTag
                dockingType={props.manoeuvre.manoeuvre_type || ''}
              />
            </Col>
            <Col span={1}>
              <Tooltip placement="bottom" title="Ver mais">
                <Button
                  icon={<EyeFilled />}
                  onClick={() => props.onOpenManoeuvre(props.manoeuvre)}
                  disabled={!props.canOpenManoeuvre}
                />
              </Tooltip>
            </Col>
          </Row>
        </div>
      </div>
    </ManoeuvreItem>
  );
}

type StatusLineProps = {
  status?: string | null;
};

function StatusLine(props: StatusLineProps) {
  const theme = {
    background: 'black',
  };

  if (props.status === 'SCHEDULED') {
    // prevista
    theme.background = 'var(--marine_dark)';
  } else if (props.status === 'IN_PROGRESS') {
    // em andamento
    theme.background = 'var(--green-sea_dark)';
  } else if (props.status === 'CONFIRMED') {
    // confirmada
    theme.background = '#fa8c16';
  } else if (props.status === 'OVERDUE') {
    // finalizada
    theme.background = '#8fbfd6';
  }

  return (
    <ThemeProvider theme={theme}>
      <StatusLineStyle>
        <div className="status-line" />
        {props.status && (
          <Tooltip
            placement="bottom"
            title={translateManoeuvreStatusName(props.status)}
          >
            <div className="status" />
          </Tooltip>
        )}
      </StatusLineStyle>
    </ThemeProvider>
  );
}

type DockingTypeTagProps = {
  dockingType: string;
};

function DockingTypeTag(props: DockingTypeTagProps) {
  let theme = {};
  let text = '';
  if (props.dockingType === 'DOCKING') {
    theme = {
      background: '#e6fffb',
      borderColor: '#87e8de',
      color: '#13c2c2',
    };
    text = 'Atracação';
  } else if (
    props.dockingType === 'UNDOCKING' ||
    props.dockingType === 'UNDOCKING_AND_LEAVE'
  ) {
    theme = {
      background: '#f5f5f5',
      borderColor: '#d9d9d9',
      color: '#595959',
    };
    if (props.dockingType === 'UNDOCKING') {
      text = 'Desatracação';
    } else if (props.dockingType === 'UNDOCKING_AND_LEAVE') {
      text = 'Desatracação e saída';
    }
  } else if (props.dockingType === 'UNDOCKING_AND_DOCKING') {
    theme = {
      background: '#e6f7ff',
      borderColor: '#91d5ff',
      color: '#1890ff',
    };
    text = 'Desatracação e atracação';
  }
  return <Tag style={{ ...theme }}>{text}</Tag>;
}
