import { ConfigProvider, Spin } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';

import { SlimTable, TableWrapper } from '../../../../../components';
import {
  formatNumberToLocale,
  isNullOrUndefined,
} from '../../../../../utils/utils';
import { columns } from './columns';
import { useGetMerchantsCeGroupbyNcmItemsQuery } from '../../../../../services/ncmItemApi';
import { useChangePagination } from '../../../../../hooks/useChangePagination';

type AssignLoadingTableProps = {
  manifestCode: string;
  stopoverId: number;
  operatorId: number;
  operatorCompanyId: number;
  totalWeight: number;
  totalItems: number;
  cargosNotAssigned?: boolean;
  isLoading?: boolean;
  rowKey?: string;
  onSelectItem: (e: boolean, record: any) => void;
  rowSelection?: any; // tive que manter any, O tipo correto seria TableRowSelection<object> mas o esLint reclama de usar object como type e se tentar passar Record<string, any> não funciona pq não é object
  hasCheckboxColumn: boolean;
  isVehicleManifest: boolean;
  setNcmIdsToEdit?: Dispatch<SetStateAction<number[]>> | undefined;
};

export function AssignLoadingTable(props: AssignLoadingTableProps) {
  const {
    manifestCode,
    stopoverId,
    operatorId,
    operatorCompanyId,
    totalWeight,
    totalItems,
    cargosNotAssigned,
    isLoading,
    onSelectItem,
    rowKey,
    rowSelection,
    hasCheckboxColumn,
    isVehicleManifest,
    setNcmIdsToEdit,
  } = props;

  const [checkedAll, setCheckedAll] = useState(false);
  const PAGE_SIZE = (hasCheckboxColumn && totalItems) || 15;
  const { onChangePagination, queryPage } = useChangePagination();

  const { data: ncmItemsData, isFetching: isFetchingNcmItemsData } =
    useGetMerchantsCeGroupbyNcmItemsQuery(
      {
        manifest_code: manifestCode,
        operator_id: operatorId,
        stopover_id: stopoverId,
        operator_company_id: operatorCompanyId,
        ncm_item_assigned: true,
        cargo_assigned: cargosNotAssigned ? false : '',
        page: onChangePagination(),
        page_size: PAGE_SIZE,
      },
      { skip: isNullOrUndefined(stopoverId) }
    );

  function handleCheckedAllManifestNcmItems(isChecked: boolean) {
    setCheckedAll(isChecked);
    if (setNcmIdsToEdit) {
      if (isChecked) {
        setNcmIdsToEdit((prev) => [
          ...prev,
          ...(ncmItemsData?.results
            .filter(
              (item) =>
                prev &&
                item &&
                item.ncm_item_id &&
                !prev.includes(item.ncm_item_id)
            )
            .map((item) => item.ncm_item_id) || []),
        ]);
      } else {
        setNcmIdsToEdit([]);
      }
    }
  }

  function customizeRenderEmpty() {
    return <>Não há dados cadastrados.</>;
  }

  function getTableSummary() {
    return (
      <SlimTable.Summary fixed>
        <SlimTable.Summary.Row key="row_0">
          <SlimTable.Summary.Cell index={0} colSpan={2} key={0} align="left">
            TOTAIS: {totalItems || 0}
          </SlimTable.Summary.Cell>
          {!isVehicleManifest && (
            <SlimTable.Summary.Cell colSpan={5} index={1} key={1} align="right">
              {formatNumberToLocale(totalWeight)} ton
            </SlimTable.Summary.Cell>
          )}
        </SlimTable.Summary.Row>
      </SlimTable.Summary>
    );
  }

  return (
    <Spin spinning={isFetchingNcmItemsData} tip="Carregando...">
      <TableWrapper>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <SlimTable
            rowKey={rowKey || 'key'}
            columns={columns(
              onSelectItem,
              hasCheckboxColumn,
              isVehicleManifest,
              checkedAll,
              handleCheckedAllManifestNcmItems
            )}
            dataSource={ncmItemsData?.results || []}
            rowSelection={rowSelection}
            className="read-only"
            pagination={{
              position: ['bottomCenter'],
              pageSize: PAGE_SIZE,
              defaultPageSize: PAGE_SIZE,
              total: totalItems,
              onChange: onChangePagination,
              showSizeChanger: false,
              current: queryPage,
            }}
            summary={() => getTableSummary()}
            scroll={{ x: '100%' }}
          />
        </ConfigProvider>
      </TableWrapper>
    </Spin>
  );
}
