import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FormInstance, message, Row } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { debounce, isEmpty } from 'lodash';
import moment, { Moment } from 'moment';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import {
  Collapse,
  Drawer,
  Form,
  FormItemDatePicker,
  FormItemInputNumber,
  FormItemRadioGroup,
  FormItemSelect,
  GenericDrawerHeader,
  Modal,
  ModalBlockNavigation,
} from '../../components';
import { useCollapsePanels } from '../../hooks';
import {
  useLazyGetMerchantsCeGroupbyNcmItemsQuery,
  usePatchNcmItemMutation,
} from '../../services/ncmItemApi';
import {
  useCreateOperationMutation,
  useDeleteOperationMutation,
  useUpdateOperationMutation,
} from '../../services/operationApi';
import { useGetSystemParamByNameQuery } from '../../services/systemParamsApi';
import { useGetPortsQuery } from '../../services/vesselApi';
import { useCreateWorkPeriodMutation } from '../../services/workPeriodApi';
import {
  OperationalOperationType,
  OperationProductType,
  ShiftType,
  WorkPeriodType,
} from '../../types';
import {
  formatOperationToForm,
  formatOperationToSave,
} from '../../utils/formatters';
import { booleanList, cargoNatureList } from '../../utils/lists';
import { createDateString, isNullOrUndefined } from '../../utils/utils';
import { LoadingDataCollapse } from '../agentArea/loadingData/loadingDataCollapse/LoadingDataCollapse';
import {
  convertToCeMerchantGroupbyNcmItemType,
  groupNCMItemByOpProduct,
} from './formatters';
import { ProvisionedCargoTable } from './provisionedCargoTable/ProvisionedCargoTable';

const { Panel } = Collapse;

const GENERAL_CARGO_ID = 1;
const CONTAINER_PRODUCT_GROUP_ID = 5;

const DOCKING_GOAL_SUPPORT = 40;
const CARGO_HANDLING = 38;

const operationTypes: { [id: number]: string } = {
  20: 'SUPPORT',
  29: 'INTERMEDIATE_OPERATION',
  30: 'DEPARTURE',
  32: 'INTERNAL_TRANSFER',
  0: 'NONE',
};

const productGroupList = [
  { id: 0, name: 'Carga Geral Comum' },
  { id: 5, name: 'Container' },
  { id: 71, name: 'Veículos' },
];

type OperationalOperationDrawerProps = {
  dockingId?: number;
  addOperationToForm: (
    defaultValue?: any,
    insertIndex?: number | undefined
  ) => void;
  removeOperationFromForm: (
    defaultValue?: any,
    insertIndex?: number | undefined
  ) => void;
  isVisibleDrawer: boolean;
  setIsVisibleDrawer: Dispatch<SetStateAction<boolean>>;
  selectedOperation?: OperationalOperationType;
  selectedOperationIndex: number;
  setSelectedOperation: Dispatch<SetStateAction<OperationalOperationType>>;
  dockingForm: FormInstance;
  stopoverId: number;
  ncmIds: number[];
  setNcmIds: Dispatch<SetStateAction<number[]>>;
};

type PreSubmitType = {
  operation: OperationalOperationType;
  oProduct: OperationProductType[];
  storagesWithoutEmail: string[];
  isLiquidBulk: boolean;
};

export function OperationalOperationDrawer(
  props: OperationalOperationDrawerProps
) {
  const {
    dockingId,
    addOperationToForm,
    removeOperationFromForm,
    isVisibleDrawer,
    setIsVisibleDrawer,
    selectedOperation,
    selectedOperationIndex,
    setSelectedOperation,
    dockingForm,
    stopoverId,
    ncmIds,
    setNcmIds,
  } = props;

  const [form] = Form.useForm();

  const [portLoadingName, setPortLoadingName] = useState('');
  const [portUnloadingName, setPortUnloadingName] = useState('');

  const [cargoOperationType, setCargoOperationType] = useState('');

  const [
    getMerchantsCeGroupbyNcmItems,
    { isLoading: isLoadingGetMerchantsCeGroupbyNcmItems },
  ] = useLazyGetMerchantsCeGroupbyNcmItemsQuery();

  const { data: operationTypesData, isLoading: isLoadingOperationTypesData } =
    useGetSystemParamByNameQuery({
      name: 'OPERATION_TYPE',
    });

  const { data: portsLoadingData, isLoading: isLoadingPortsLoadingData } =
    useGetPortsQuery({ name: portLoadingName });

  const { data: portsUnloadingData, isLoading: isLoadingPortsUnloadingData } =
    useGetPortsQuery({ name: portUnloadingName });

  const [
    createOperation,
    {
      isLoading: isLoadingAddOperation,
      isSuccess: isSuccessCreateOperation,
      data: operationCreatedData,
    },
  ] = useCreateOperationMutation();

  const [
    updateOperation,
    {
      isLoading: isLoadingUpdateOperation,
      isSuccess: isSuccessUpdateOperation,
      data: operationUpdatedData,
    },
  ] = useUpdateOperationMutation();

  const [patchNcmItem, { isLoading: isLoadingPatchNcmItem }] =
    usePatchNcmItemMutation();

  const [
    deleteOperation,
    {
      isSuccess: isSuccessDeleteOperation,
      isError: isErrorDeleteOperation,
      isLoading: isLoadingDeleteOperation,
    },
  ] = useDeleteOperationMutation();

  const [blockNavigate, setBlockNavigate] = useState(false);
  const [hasEdited, setHasEdited] = useState(false);
  const [natureOfCargo, setNatureOfCargo] = useState<string | undefined>(
    undefined
  );
  const [isCargoTypeManifested, setIsCargoTypeManifested] = useState(
    selectedOperation?.provisioned_cargos?.length === 0
  );

  const [dataPreSubmit, setDataPreSubmit] = useState<PreSubmitType>(
    {} as PreSubmitType
  );

  const [isVisibleModalConfirmAssociate, setIsVisibleModalConfirmAssociate] =
    useState(false);

  const { onOpenPanel, openCollapsePanels } = useCollapsePanels(['cargos']);

  // Uma vez a operação criada com guindastes ou produtos, não deve ser possível alterar a natureza da carga/grupo de produto
  const disableEditNatureOfCargo = useMemo(() => {
    return (
      (selectedOperation?.items && selectedOperation?.items.length > 0) ||
      (selectedOperation?.operation_cranes &&
        selectedOperation?.operation_cranes?.length > 0)
    );
  }, [selectedOperation]);

  const notHasCranes = useMemo(() => {
    return (
      !selectedOperation?.operation_cranes ||
      (selectedOperation?.operation_cranes &&
        selectedOperation?.operation_cranes.length === 0)
    );
  }, [selectedOperation]);

  const [isContainerOperation, setIsContainerOperation] = useState(false);

  function isVehicle(operation: OperationalOperationType) {
    return (
      operation.nature_of_cargo?.id === 1 && operation.product_group?.id === 71
    );
  }

  function onCloseDrawer() {
    setIsVisibleDrawer(false);
    setBlockNavigate(false);
    setHasEdited(false);
    setSelectedOperation({} as OperationalOperationType);
    setNatureOfCargo(undefined);
    form.resetFields();
    setIsVisibleModalConfirmAssociate(false);
    setIsContainerOperation(false);
    setCargoOperationType('');
    setPortLoadingName('');
    setPortUnloadingName('');
  }

  async function handleDeleteOperation() {
    confirm({
      title: 'Deseja realmente remover essa operação?',
      icon: <ExclamationCircleOutlined />,
      content: 'A operação removida não poderá ser recuperada',
      async onOk() {
        if (selectedOperation) {
          if (selectedOperation.id) {
            await deleteOperation(selectedOperation.id);
          } else {
            removeOperationFromForm(selectedOperationIndex);
            message.success('Operação removida com sucesso');
            onCloseDrawer();
          }
        }
      },
    });
  }

  function onChangeNatureOfCargo(cargoId: number | undefined) {
    const productGroup = form.getFieldValue(['product_group', 'id']);
    setIsContainerOperation(false);
    if (productGroup === CONTAINER_PRODUCT_GROUP_ID) {
      setIsContainerOperation(true);
    }
    if (cargoId) {
      if (cargoId !== GENERAL_CARGO_ID) {
        form.setFieldsValue({ product_group: null });
      }
      setNatureOfCargo(
        cargoNatureList.find((cargo) => cargo.id === cargoId)?.state_name
      );
    }
  }

  useEffect(() => {
    if (isSuccessDeleteOperation) {
      removeOperationFromForm(selectedOperationIndex);
      message.success('Operação removida com sucesso');
      onCloseDrawer();
    } else if (isErrorDeleteOperation) {
      message.error(
        'Ocorreu um erro ao excluir uma operação, se o erro persistir entre em contato com o suporte'
      );
    }
  }, [isSuccessDeleteOperation, isErrorDeleteOperation]);

  useEffect(() => {
    onChangeNatureOfCargo(selectedOperation?.nature_of_cargo?.id);
    setIsCargoTypeManifested(
      !(
        selectedOperation?.provisioned_cargos &&
        selectedOperation?.provisioned_cargos?.length > 0
      )
    );
    form.setFieldsValue(selectedOperation);

    let operationType = null;
    let operationDirection = null;
    let loadingPort = null;
    let unloadingPort = null;
    if (
      selectedOperation?.provisioned_cargos &&
      selectedOperation?.provisioned_cargos.length > 0
    ) {
      operationType =
        selectedOperation?.provisioned_cargos[0].operation_type || null;
      operationDirection =
        selectedOperation?.provisioned_cargos[0].operation_direction || null;
      loadingPort =
        selectedOperation?.provisioned_cargos[0].loading_port || null;
      unloadingPort =
        selectedOperation?.provisioned_cargos[0].unloading_port || null;
      handleSelectCargoOperationType(
        selectedOperation?.provisioned_cargos[0].operation_type?.id || 0
      );
    }

    form.setFieldsValue({
      is_manifested_cargo: !(
        selectedOperation?.provisioned_cargos &&
        selectedOperation?.provisioned_cargos?.length > 0
      ),
      operation_type: operationType,
      operation_direction: operationDirection,
      loading_port: loadingPort,
      unloading_port: unloadingPort,
    });
    if (
      selectedOperation?.nature_of_cargo?.id === GENERAL_CARGO_ID &&
      selectedOperation.product_group === null
    ) {
      form.setFieldsValue({ product_group: { id: 0 } });
    }
  }, [isVisibleDrawer]);

  useEffect(() => {
    if (isSuccessCreateOperation) {
      addOperationToForm(operationCreatedData);
      onCloseDrawer();
    }
  }, [isSuccessCreateOperation]);

  useEffect(() => {
    if (isSuccessUpdateOperation) {
      const operations = dockingForm.getFieldValue('operations');
      dockingForm.setFieldsValue({
        ...dockingForm,
        operations: operations.map((operation: OperationalOperationType) => {
          if (
            operationUpdatedData &&
            operation.id === operationUpdatedData?.id
          ) {
            return formatOperationToForm(operationUpdatedData);
          }
          return operation;
        }),
      });
      onCloseDrawer();
      setNcmIds([]);
    }
  }, [isSuccessUpdateOperation]);

  function validateOperationExpectedDates(
    start: string | Moment | null,
    end: string | Moment | null
  ) {
    if (start && end && start > end) {
      message.error(
        'Data de previsão de início da primeira operação não pode ser maior que previsão de fim da última operação',
        15
      );
      return false;
    }
    return true;
  }

  const [
    createWorkPeriod,
    {
      isLoading: isLoadingCreatePeriod,
      isSuccess: isSuccessCreatePeriod,
      isError: isErrorCreatePeriod,
    },
  ] = useCreateWorkPeriodMutation();

  function continueSubmit(
    values: any,
    formattedOperationProduct: OperationProductType[],
    isProvisionedCargo = false
  ) {
    console.log(values);
    console.log(formattedOperationProduct);
    // let operationProductToAdd = {
    //   ...formattedOperationProduct[0],
    // };
    // let operationProductToAdd = [
    //   ...formattedOperationProduct
    // ]

    const productsToAdd: any[] = [];
    values.docking_id = dockingId;

    // const existingProductItem = selectedOperation?.items?.find(
    //   (item) =>
    //     item.cargo_type?.name === operationProductToAdd.cargo_type?.name &&
    //     item.operator?.id === operationProductToAdd.operator?.id
    // );

    // checa se o produto já existe
    // if (!isProvisionedCargo && existingProductItem) {
    // caso exista atualiza com os dados dele
    // operationProductToAdd = {
    //   ...existingProductItem,
    //   id: existingProductItem.id,
    // };

    formattedOperationProduct.map((operationProduct) => {
      let operationProductToAdd = {
        ...operationProduct,
      };
      const existingProductItem = selectedOperation?.items?.find(
        (item) =>
          item.cargo_type?.name === operationProduct.cargo_type?.name &&
          item.operator?.id === operationProduct.operator?.id
      );

      console.log(existingProductItem);
      if (!isProvisionedCargo && existingProductItem) {
        operationProductToAdd = {
          ...existingProductItem,
          id: existingProductItem.id,
        };
      }
      // itera sobre todos os manifestos para adicionar checando se ja existe
      operationProduct.operation_manifests?.forEach((om, index) => {
        // checa se o manifest code ja existe
        const existingManifest = existingProductItem?.operation_manifests?.find(
          (o) => o.manifest_code === om.manifest_code
        );
        // caso exista, apenas atualiza os dados do manifesto selecionado
        if (existingManifest) {
          let existingManifestIndex = -1;
          if (existingProductItem?.operation_manifests) {
            existingManifestIndex =
              existingProductItem.operation_manifests.findIndex(
                (manifest) => manifest.id === existingManifest.id
              );
          }
          if (!operationProductToAdd.operation_manifests) {
            operationProductToAdd.operation_manifests = [];
          }
          // itera sobre todos os merchantCe para adicionar checando se ja existe
          if (!operationProduct.operation_manifests)
            operationProduct.operation_manifests = [];
          operationProduct.operation_manifests[index].merchants_ce.forEach(
            (mce, indexMce) => {
              // checa se o mce já existe
              const existingMCe = existingManifest.merchants_ce.find(
                (mc) => mc.code === mce.code
              );

              if (!operationProductToAdd.operation_manifests)
                operationProductToAdd.operation_manifests = [];
              if (
                existingProductItem &&
                !existingProductItem?.operation_manifests
              )
                existingProductItem.operation_manifests = [];
              // caso exista, apenas atualiza os dados do merchantCe selecionado
              let existingMceIndex = -1;
              if (existingMCe)
                existingMceIndex =
                  existingManifest.merchants_ce?.indexOf(existingMCe);
              if (existingMCe) {
                // itera sobre todos os cargoStorage para adicionar checando se ja existe
                if (!operationProduct.operation_manifests)
                  operationProduct.operation_manifests = [];
                operationProduct.operation_manifests[index].merchants_ce[
                  indexMce
                ].storages?.forEach((cs) => {
                  if (operationProductToAdd.operation_manifests) {
                    const existingCargoStorage = existingMCe.storages?.find(
                      (c) => c.storage_company?.id === cs.storage_company?.id
                    );
                    // caso exista, apenas atualiza os dados do merchantCe selecionado e
                    // adiciona a mercadoria na listagem de cargos_storage
                    if (existingCargoStorage) {
                      const existingCSIndex =
                        existingMCe.storages?.indexOf(existingCargoStorage);
                      operationProductToAdd.operation_manifests[
                        existingManifestIndex
                      ].merchants_ce[existingMceIndex].storages[
                        existingCSIndex
                      ].cargos_storage = [
                        ...existingCargoStorage.cargos_storage,
                        ...cs.cargos_storage,
                      ];
                      // caso nao exista, apenas adiciona o cs ao mce já existente
                    } else if (
                      existingProductItem?.operation_manifests &&
                      existingProductItem?.operation_manifests[
                        existingManifestIndex
                      ]
                    ) {
                      // adiciona na listagem os novos csm e os já existentes
                      operationProductToAdd.operation_manifests[
                        existingManifestIndex
                      ].merchants_ce[existingMceIndex].storages = [
                        ...(existingProductItem?.operation_manifests[
                          existingManifestIndex
                        ].merchants_ce[existingMceIndex].storages || []),
                        cs,
                      ];
                    }
                  }
                });

                // caso nao exista, apenas adiciona o mce ao manifesto já existente
              } else if (
                existingProductItem &&
                existingProductItem.operation_manifests
              ) {
                operationProductToAdd.operation_manifests[
                  existingManifestIndex
                ].merchants_ce = [
                  ...(existingProductItem.operation_manifests[
                    existingManifestIndex
                  ].merchants_ce || []),
                  mce,
                ];
              }
            }
          );

          // caso nao exista, apenas adiciona o manifesto ao produto já existente
        } else {
          operationProductToAdd.operation_manifests = [
            ...(operationProduct?.operation_manifests || []),
          ];
        }
      });
      productsToAdd.push(operationProductToAdd);
    });
    // }

    console.log(formattedOperationProduct);
    console.log(productsToAdd);

    console.log(values);
    const opToSave = formatOperationToSave({
      ...values,
      docking_id: dockingId,
      items: isProvisionedCargo
        ? formattedOperationProduct
        : isEmpty(productsToAdd)
        ? []
        : productsToAdd,
      id: selectedOperation?.id,
    });

    console.log(opToSave);

    if (dockingId) {
      if (isEmpty(selectedOperation)) {
        createOperation(opToSave).then((operation) => {
          if ('data' in operation) {
            if (isVehicle(operation.data) && !isProvisionedCargo) {
              createWorkPeriod({
                start_of_period: createDateString(moment()),
                end_of_period: createDateString(moment()),
                shift_mode: 'SIX',
                operation_id: operation.data.id,
                work_shifts: [
                  {
                    shift_start: createDateString(moment()),
                    shift_end: createDateString(moment()),
                    gang_amount: '0',
                  } as ShiftType,
                ],
              } as WorkPeriodType);
            }
          }
        });
      } else {
        updateOperation(opToSave);
      }
      patchNcmItem(
        ncmIds.map((ncmId) => ({ id: ncmId, cargo_assigned: true }))
      );

      setNcmIds([]);
    } else {
      values.nature_of_cargo = cargoNatureList.find(
        (item) => item.id === values.nature_of_cargo?.id
      );
      values.product_group =
        productGroupList.find((item) => item.id === values.product_group?.id) ||
        null;
      if (isEmpty(selectedOperation)) {
        addOperationToForm({
          ...values,
          docking_id: dockingId,
          items: formattedOperationProduct,
        });
      } else {
        const operations = dockingForm.getFieldValue('operations');
        dockingForm.setFieldsValue({
          ...dockingForm,
          operations: operations.map(
            (operation: OperationalOperationType, index: number) => {
              return index === selectedOperationIndex ? values : operation;
            }
          ),
        });
      }
      onCloseDrawer();
    }
  }

  async function handleFormSubmit() {
    await getMerchantsCeGroupbyNcmItems({
      stopover_id: stopoverId,
      ncm_item_assigned: true,
      ncm_ids: ncmIds.join(','),
      page_size: ncmIds.length,
    }).then((response) => {
      form.validateFields().then(async (values: OperationalOperationType) => {
        if (
          values.expected_operation_start instanceof moment &&
          values.expected_operation_finish instanceof moment &&
          !validateOperationExpectedDates(
            values.expected_operation_start,
            values.expected_operation_finish
          )
        ) {
          return;
        }

        if (ncmIds.length > 0) {
          const isLiquidBulk = values.nature_of_cargo?.id === 2;
          const storagesWithoutEmail = new Set<string>();

          if (isLiquidBulk) {
            response.data?.results.forEach((item) => {
              if (
                item.storage_terminal &&
                isNullOrUndefined(item.storage_terminal?.email)
              ) {
                storagesWithoutEmail.add(item.storage_terminal.name);
              }
            });
          }

          const formattedOperationProduct = groupNCMItemByOpProduct(
            response.data?.results || [],
            selectedOperation?.id || 0
          );

          setDataPreSubmit({
            operation: values,
            oProduct: formattedOperationProduct,
            storagesWithoutEmail: Array.from(storagesWithoutEmail),
            isLiquidBulk,
          });
          setIsVisibleModalConfirmAssociate(true);
        } else {
          // considera apenas os items adicionados
          const newsProvisionedCargos = values.provisioned_cargos
            ?.filter((item) => !item.id)
            .map((item) => ({
              ...item,
              loading_port: values.loading_port,
              unloading_port: values.unloading_port,
            }));

          const provisionedCargos = convertToCeMerchantGroupbyNcmItemType(
            newsProvisionedCargos
          );
          const formattedOperationProduct = groupNCMItemByOpProduct(
            provisionedCargos || [],
            selectedOperation?.id || 0
          );
          continueSubmit(values, formattedOperationProduct, true);
        }
      });
    });
  }

  function handleSelectCargoOperationType(e: number) {
    setCargoOperationType(operationTypes[e]);
  }

  const hasDirection = useMemo(() => {
    return (
      (dockingForm.getFieldValue(['docking_goal', 'id']) ===
        DOCKING_GOAL_SUPPORT &&
        cargoOperationType === 'SUPPORT') ||
      (dockingForm.getFieldValue(['docking_goal', 'id']) === CARGO_HANDLING &&
        (cargoOperationType === 'INTERMEDIATE_OPERATION' ||
          cargoOperationType === 'INTERNAL_TRANSFER'))
    );
  }, [cargoOperationType]);

  return (
    <>
      <Modal
        title="Tem certeza que deseja associar estas cargas a esta operação?"
        visible={isVisibleModalConfirmAssociate}
        className="TOSPrimaryModal"
        okText="Continuar"
        cancelText="Voltar"
        width={640}
        okButtonProps={{
          loading:
            isLoadingUpdateOperation ||
            isLoadingAddOperation ||
            isLoadingCreatePeriod,
        }}
        onOk={() =>
          continueSubmit(dataPreSubmit.operation, dataPreSubmit.oProduct)
        }
        onCancel={() => setIsVisibleModalConfirmAssociate(false)}
      >
        <div>
          <span>
            Ao associar as cargas a esta operação, não será possível editá-las
            posteriormente.
          </span>
          <br />
          <br />
          <span>
            {dataPreSubmit.storagesWithoutEmail &&
            dataPreSubmit.storagesWithoutEmail.length > 0
              ? 'Porém, os terminais de armazenamento envolvidos abaixo não serão notificados, pois não possuem emails cadastrados no sistema. Por favor, entre em contato com Suape para que cadastre essas informações.'
              : dataPreSubmit.isLiquidBulk
              ? 'Além disso, os terminais de armazenamento envolvidos serão notificados sobre esse armazenamento.'
              : ''}
          </span>
          <br />
          {dataPreSubmit.storagesWithoutEmail &&
            dataPreSubmit.storagesWithoutEmail.length > 0 && (
              <ul style={{ marginTop: '10px' }}>
                {dataPreSubmit.storagesWithoutEmail.map((company) => (
                  <li key={company}>{company}</li>
                ))}
              </ul>
            )}
        </div>
      </Modal>
      <ModalBlockNavigation
        name="create_operation_by_agent"
        key="create_operation_by_agent"
        setHasEdited={setHasEdited}
        showModal={blockNavigate}
        setShowModal={setBlockNavigate}
        setBlockNavigate={setBlockNavigate}
        afterCloseModal={() => {
          onCloseDrawer();
          setNcmIds([]);
        }}
      />
      <Drawer
        visible={isVisibleDrawer}
        placement="right"
        width={900}
        closable={false}
        destroyOnClose
        onClose={() => {
          if (hasEdited) {
            setBlockNavigate(true);
          } else {
            onCloseDrawer();
            setNcmIds([]);
          }
        }}
      >
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          name="create_operation_by_agent"
          id="create_operation_by_agent"
          onFinish={handleFormSubmit}
          onValuesChange={() => {
            setHasEdited(true);
          }}
        >
          <GenericDrawerHeader
            onBack={() => {
              if (hasEdited) {
                setBlockNavigate(true);
              } else {
                onCloseDrawer();
              }
            }}
            title={
              isEmpty(selectedOperation) ? (
                <span style={{ fontWeight: '700', fontSize: '16px' }}>
                  Nova operação
                </span>
              ) : (
                <span style={{ fontWeight: '700', fontSize: '16px' }}>
                  Operação {selectedOperation?.id}
                </span>
              )
            }
            onDelete={handleDeleteOperation}
            showBackButton
            formName="create_operation_by_agent"
            disabledSaveButton={
              isLoadingAddOperation ||
              isLoadingUpdateOperation ||
              isLoadingGetMerchantsCeGroupbyNcmItems ||
              isLoadingPatchNcmItem ||
              isLoadingDeleteOperation ||
              isLoadingCreatePeriod
            }
            isLoadingSave={
              isLoadingAddOperation ||
              isLoadingUpdateOperation ||
              isLoadingGetMerchantsCeGroupbyNcmItems ||
              isLoadingPatchNcmItem ||
              isLoadingDeleteOperation ||
              isLoadingCreatePeriod
            }
          />
          <div style={{ padding: '24px 16px' }}>
            <Row gutter={24} align="bottom">
              <FormItemRadioGroup
                label="Selecione o tipo da carga:"
                name="is_manifested_cargo"
                required
                onChange={(e) => setIsCargoTypeManifested(e.target.value)}
                options={[
                  {
                    label: 'Carga manifestada',
                    value: true,
                    disabled:
                      form.getFieldValue('provisioned_cargos')?.length > 0,
                  },
                  {
                    label: 'Provisões',
                    value: false,
                    disabled: ncmIds.length > 0,
                  },
                ]}
              />
            </Row>
            <Row gutter={20} align="top">
              <FormItemSelect
                colSpan={6}
                name={['nature_of_cargo', 'id']}
                label="Natureza da carga"
                dataList={cargoNatureList
                  .slice()
                  .sort((a, b) => a.name.localeCompare(b.name))}
                onSelect={(id) => onChangeNatureOfCargo(id)}
                required
                disabled={disableEditNatureOfCargo}
              />
              {natureOfCargo === 'GENERAL_CARGO' ? (
                <>
                  <FormItemSelect
                    colSpan={12}
                    name={['product_group', 'id']}
                    label="Tipo de carga geral"
                    dataList={productGroupList
                      .slice()
                      .sort((a, b) => a.name.localeCompare(b.name))}
                    allowClear
                    disabled={disableEditNatureOfCargo}
                    onSelect={(id) => {
                      setIsContainerOperation(false);
                      if (id === CONTAINER_PRODUCT_GROUP_ID) {
                        setIsContainerOperation(true);
                      }
                    }}
                  />
                  <FormItemSelect
                    colSpan={6}
                    name="inspection"
                    label="Haverá inspeção?"
                    dataList={booleanList}
                  />
                </>
              ) : (
                natureOfCargo === 'LIQUID_BULK' && (
                  <>
                    <FormItemSelect
                      colSpan={6}
                      name="is_cargo_transshipment"
                      label="Transbordo?"
                      dataList={booleanList}
                    />
                    <FormItemInputNumber
                      colSpan={6}
                      name="flow_rate"
                      label="Vazão da operação"
                      addonAfter="m³/h"
                    />
                    <FormItemInputNumber
                      colSpan={6}
                      name="manifolds"
                      label="Quantidade de manifolds"
                    />
                  </>
                )
              )}
            </Row>
            {!form.getFieldValue('is_manifested_cargo') && (
              <>
                <Row gutter={20} align="top">
                  <FormItemSelect
                    colSpan={12}
                    name={['operation_type', 'id']}
                    label="Tipo da operação da carga"
                    notFoundContent="Não foi encontrado nenhum sentido da operação"
                    dataList={operationTypesData?.items}
                    isLoading={isLoadingOperationTypesData}
                    onSelect={handleSelectCargoOperationType}
                  />
                  {hasDirection && (
                    <FormItemSelect
                      colSpan={12}
                      name="operation_direction"
                      label="Sentido"
                      dataList={[
                        { id: 'LOADING', name: 'Embarque' },
                        { id: 'UNLOADING', name: 'Desembarque' },
                      ]}
                    />
                  )}
                </Row>
                {cargoOperationType === 'SUPPORT' &&
                  dockingForm.getFieldValue(['docking_goal', 'id']) ===
                    DOCKING_GOAL_SUPPORT && (
                    <Row gutter={20} align="top">
                      <FormItemSelect
                        colSpan={12}
                        name={['loading_port', 'bi_trigram']}
                        label="Porto de embarque"
                        allowClear
                        showSearch
                        onSearch={debounce((e) => setPortLoadingName(e), 400)}
                        onClear={() =>
                          form.setFieldsValue({ port_boarding: null })
                        }
                        dataList={portsLoadingData?.results
                          .slice()
                          .sort((a, b) => a.name.localeCompare(b.name))}
                        isLoading={isLoadingPortsLoadingData}
                        notFoundContent="Nenhum porto encontrado para o filtro informado"
                      />
                      <FormItemSelect
                        colSpan={12}
                        name={['unloading_port', 'bi_trigram']}
                        label="Porto de desembarque"
                        allowClear
                        showSearch
                        onSearch={debounce((e) => setPortUnloadingName(e), 400)}
                        onClear={() =>
                          form.setFieldsValue({ port_Unloading: null })
                        }
                        dataList={portsUnloadingData?.results
                          .slice()
                          .sort((a, b) => a.name.localeCompare(b.name))}
                        isLoading={isLoadingPortsUnloadingData}
                        notFoundContent="Nenhum porto encontrado para o filtro informado"
                      />
                    </Row>
                  )}
              </>
            )}
            <Row gutter={20} align="top">
              <FormItemDatePicker
                colSpan={12}
                label="Previsão de início da primeira operação"
                name="expected_operation_start"
                minuteStep={15}
              />
              <FormItemDatePicker
                colSpan={12}
                label="Previsão de fim da última operação"
                name="expected_operation_finish"
                minuteStep={15}
              />
            </Row>
          </div>
          {notHasCranes && (
            <Collapse
              expandIconPosition="end"
              activeKey={openCollapsePanels}
              onChange={onOpenPanel}
            >
              <Panel forceRender header="Carga" key="cargos">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {isCargoTypeManifested ? (
                    <div>
                      {!isContainerOperation && (
                        <div>
                          <span style={{ marginBottom: '10px' }}>
                            Selecione apenas as cargas que serão movimentadas
                            nesta operação:
                          </span>
                          <LoadingDataCollapse
                            stopoverId={stopoverId}
                            setNcmIdsToEdit={setNcmIds}
                            cargosNotAssigned
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <ProvisionedCargoTable
                      hasAmount={
                        (dockingForm.getFieldValue(['docking_goal', 'id']) ===
                          DOCKING_GOAL_SUPPORT &&
                          cargoOperationType === 'SUPPORT') ||
                        cargoOperationType === 'DEPARTURE'
                      }
                      hasNcm={
                        (dockingForm.getFieldValue(['docking_goal', 'id']) ===
                          CARGO_HANDLING &&
                          (cargoOperationType === 'INTERMEDIATE_OPERATION' ||
                            cargoOperationType === 'INTERNAL_TRANSFER')) ||
                        cargoOperationType === 'DEPARTURE'
                      }
                      operation={
                        selectedOperation || ({} as OperationalOperationType)
                      }
                    />
                  )}
                </div>
              </Panel>
            </Collapse>
          )}
        </Form>
      </Drawer>
    </>
  );
}
