import { TableType } from '../typesFinancial';

export const docsList = [
  { dc_type: 'DOCUMENT', label: 'CAA', name: 'caa', checked: false },
  { dc_type: 'DOCUMENT', label: 'CLP', name: 'clp', checked: false },
  {
    dc_type: 'DOCUMENT',
    label: 'Confirmação de fonte pagadora',
    name: 'paying_source_confirmation',
    checked: false,
  },
  {
    dc_type: 'DOCUMENT',
    label: 'Declaração de carga perigosa',
    name: 'dangerous_cargo_confirmation',
    checked: false,
  },
  {
    dc_type: 'DOCUMENT',
    label: 'Documentações ISPS CODE',
    name: 'isps_code_documentation',
    checked: false,
  },
  { dc_type: 'DOCUMENT', label: 'Manifesto', name: 'manifest', checked: false },
  {
    dc_type: 'DOCUMENT',
    label: 'Ship particulars',
    name: 'private_vessel',
    checked: false,
  },
  {
    dc_type: 'DOCUMENT',
    label: 'Solicitação de reatracação',
    name: 'redocking_request',
    checked: false,
  },
];

export const consentsList = [
  {
    dc_type: 'CONSENT',
    label: 'Autoridade Sanitária',
    name: 'health_authority',
    checked: false,
  },
  {
    dc_type: 'CONSENT',
    label: 'Autoridade Marítima',
    name: 'maritime_authority',
    checked: false,
  },
  {
    dc_type: 'CONSENT',
    label: 'Adm. Portuária - Meio ambiente',
    name: 'port_admin_environment',
    checked: false,
  },
  {
    dc_type: 'CONSENT',
    label: 'Adm. Portuária - Provisão',
    name: 'port_admin_provision',
    checked: false,
  },
  {
    dc_type: 'CONSENT',
    label: 'Adm. Portuária - Risco',
    name: 'port_admin_risk',
    checked: false,
  },
  {
    dc_type: 'CONSENT',
    label: 'Adm. Portuária - Segurança',
    name: 'port_admin_safety',
    checked: false,
  },
  {
    dc_type: 'CONSENT',
    label: 'Autoridade de Polícia Marítima',
    name: 'port_admin_maritime_police',
    checked: false,
  },
];

export const docsConsentsList = [...docsList, ...consentsList];

export const documentsConsentsList = [
  {
    dc_type: 'CONSENT',
    label: 'Autoridade Sanitária',
    name: 'health_authority',
    checked: false,
  },
  { dc_type: 'DOCUMENT', label: 'CLP', name: 'clp', checked: false },
  {
    dc_type: 'CONSENT',
    label: 'Adm. Portuária - Provisão',
    name: 'port_admin_provision',
    checked: false,
  },
  { dc_type: 'DOCUMENT', label: 'CAA', name: 'caa', checked: false },
  {
    dc_type: 'DOCUMENT',
    label: 'Confirmação de fonte pagadora',
    name: 'paying_source_confirmation',
    checked: false,
  },
  {
    dc_type: 'DOCUMENT',
    label: 'Associação à escala mercante',
    name: 'merchant_scale_association',
    checked: false,
  },
  {
    dc_type: 'DOCUMENT',
    label: 'Manifesto de carga',
    name: 'cargo_manifest',
    checked: false,
  },
  {
    dc_type: 'DOCUMENT',
    label: 'Confirmação de DWT de entrada',
    name: 'input_dwt_confirmation',
    checked: false,
  },
  {
    dc_type: 'CONSENT',
    label: 'Adm. Portuária - Risco',
    name: 'port_admin_risk',
    checked: false,
  },
  {
    dc_type: 'DOCUMENT',
    label: 'Declaração de carga perigosa',
    name: 'dangerous_cargo_confirmation',
    checked: false,
  },
  {
    dc_type: 'CONSENT',
    label: 'Adm. Portuária - Segurança',
    name: 'port_admin_safety',
    checked: false,
  },
  {
    dc_type: 'DOCUMENT',
    label: 'Nível de segurança em que o navio está operando',
    name: 'security_level_ship_operating',
    checked: false,
  },
  {
    dc_type: 'DOCUMENT',
    label: 'ISSC International Ship Security Certificate',
    name: 'issc',
    checked: false,
  },
];

export const operationTypes = [
  { id: 'LOAD', value: 'LOAD', name: 'Carga' },
  { id: 'UNLOAD', value: 'UNLOAD', name: 'Descarga' },
  {
    id: 'TRANSSHIPMENT_LOAD',
    value: 'TRANSSHIPMENT_LOAD',
    name: 'Transbordo de carga',
  },
  {
    id: 'TRANSSHIPMENT_UNLOAD',
    value: 'TRANSSHIPMENT_UNLOAD',
    name: 'Transbordo de descarga',
  },
];

export const unitsList = [
  { value: 'TONNES', name: 'Toneladas, ton', abbreviation: 'ton' },
  { value: 'UNITS', name: 'Unidades, un', abbreviation: 'Un' },
  { value: 'CUBIC_METERS', name: 'Metros cúbicos, m³', abbreviation: 'm³' },
];

export const userAccessStatus = [
  { name: 'DESATIVADO', value: 'DEACTIVATED' },
  { name: 'PENDENTE', value: 'PENDING' },
  { name: 'ATIVADO', value: 'ACTIVE' },
  { name: 'BLOQUEADO', value: 'BLOCKED' },
];

export const cargoNatureList = [
  { id: 1, name: 'Carga Geral', state_name: 'GENERAL_CARGO' },
  { id: 2, name: 'Granel Líquido', state_name: 'LIQUID_BULK' },
  { id: 3, name: 'Granel Sólido', state_name: 'SOLID_BULK' },
];

export const companyTypes = [
  { id: 3, name: 'Rebocagem', db_name: 'TOWING' },
  { id: 1, name: 'Agência de navegação', db_name: 'SHIPPING_AGENCY' },
  { id: 6, name: 'Praticagem', db_name: 'PILOTAGE' },
  { id: 8, name: 'Terminal de Armazenamento', db_name: 'STORAGE' },
  { id: 4, name: 'Amarração', db_name: 'MOORING' },
  { id: 2, name: 'Agência Protetora', db_name: 'PROTECTIVE_AGENCY' },
  { id: 7, name: 'Consignatários', db_name: 'CONSIGNEE' },
  { id: 5, name: 'Outros', db_name: 'OTHER' },
  { id: 9, name: 'Armadores', db_name: 'SHIPOWNER' },
  { id: 10, name: 'Expedidor', db_name: 'SHIPPER' },
  { id: 11, name: 'Operador', db_name: 'OPERATOR' },
  { id: 12, name: 'Montadora', db_name: 'AUTOMOBILE_MANUFACTURER' },
];

export const brazilStates = [
  { id: 'AC', name: 'Acre' },
  { id: 'AL', name: 'Alagoas' },
  { id: 'AP', name: 'Amapá' },
  { id: 'AM', name: 'Amazonas' },
  { id: 'BA', name: 'Bahia' },
  { id: 'CE', name: 'Ceará' },
  { id: 'DF', name: 'Distrito Federal' },
  { id: 'ES', name: 'Espírito Santo' },
  { id: 'GO', name: 'Goiás' },
  { id: 'MA', name: 'Maranhão' },
  { id: 'MT', name: 'Mato Grosso' },
  { id: 'MS', name: 'Mato Grosso do Sul' },
  { id: 'MG', name: 'Minas Gerais' },
  { id: 'PA', name: 'Pará' },
  { id: 'PB', name: 'Paraíba' },
  { id: 'PR', name: 'Paraná' },
  { id: 'PE', name: 'Pernambuco' },
  { id: 'PI', name: 'Piauí' },
  { id: 'RJ', name: 'Rio de Janeiro' },
  { id: 'RN', name: 'Rio Grande do Norte' },
  { id: 'RS', name: 'Rio Grande do Sul' },
  { id: 'RO', name: 'Rondônia' },
  { id: 'RR', name: 'Roraima' },
  { id: 'SC', name: 'Santa Catarina' },
  { id: 'SP', name: 'São Paulo' },
  { id: 'SE', name: 'Sergipe' },
  { id: 'TO', name: 'Tocantins' },
];

export const dockLineTypes = [
  { id: 'SYNTHETIC', name: 'Sintético' },
  { id: 'STEEL', name: 'Aço' },
  { id: 'NYLON', name: 'Nylon' },
];

export const shipTypesList = [
  { id: 'FERRY', name: 'Balsa' },
  { id: 'GENERAL_CARGO', name: 'Carga Geral' },
  { id: 'REFRIGERATED_CARGO', name: 'Carga Refrigerada' },
  { id: 'FLOATING_DAM', name: 'Dique Flutuante' },
  { id: 'DREDGE', name: 'Draga' },
  { id: 'FERRY_BOAT', name: 'Ferry Boat' },
  { id: 'LIQUEFIED_GASES', name: 'Gases Liquefeitos' },
  { id: 'BULK_CARRIER', name: 'Graneleiro' },
  { id: 'SISTER_SHIP', name: 'Navio Cisterna' },
  { id: 'MILITARY_SHIP', name: 'Navio Militar' },
  { id: 'DRILLSHIP', name: 'Navio Sonda' },
  { id: 'PASSENGER', name: 'Passageiro' },
  { id: 'FISHERMAN', name: 'Pesqueiro' },
  { id: 'RESEARCH', name: 'Pesquisa' },
  { id: 'TANKER', name: 'Petroleiro' },
  { id: 'PLATFORM', name: 'Plataforma' },
  { id: 'CONTAINER_DOOR', name: 'Porta Contentor' },
  { id: 'ICE_BREAKER', name: 'Quebra-gelo' },
  { id: 'CHEMICAL', name: 'Químico' },
  { id: 'ROLL_ON_OFF', name: 'Roll-On Roll-off' },
  { id: 'SUPPLY', name: 'Supply' },
  { id: 'TUGBOAT', name: 'Rebocador' },
];

export const booleanList = [
  { id: 'true', name: 'Sim' },
  { id: 'false', name: 'Não' },
];

export const supportVesselList = [
  { id: 'MARITIME_SUPPORT', name: 'Sim, apoio marítimo' },
  { id: 'PORT_SUPPORT', name: 'Sim, apoio portuário' },
  { id: 'NO', name: 'Não' },
];

export const positionDockingList = [
  { id: 'STARBOARD', name: 'Boreste' },
  { id: 'PORT_SIDE', name: 'Bombordo' },
  { id: 'MED_SIDE', name: 'Mediterrâneo' },
];

export const dockingInLineList = [
  { id: 'FIRST_VESSEL', name: 'Sim, primeira embarcação' },
  { id: 'SECOND_VESSEL', name: 'Sim, segunda embarcação' },
  { id: 'NO', name: 'Não' },
];

export const dockingAlongsideList = [
  { id: 'MAIN_DOCKING', name: 'Sim, embarcação mãe' },
  { id: 'CHILD_DOCKING', name: 'Sim, embarcação filha' },
  { id: 'NO', name: 'Não' },
];

export const dockingStatusList = [
  { status: 'WAITING', label: 'Aguardando', className: 'orange' },
  {
    status: 'WAITING_PRINCIPAL_DEFINITION',
    label: 'Aguardando definição dos principais',
    className: 'orange',
  },
  {
    status: 'WAITING_DOCKING_PLACE_RELEASE',
    label: 'Aguardando liberação do local de atracação',
    className: 'orange',
  },
  {
    status: 'WAITING_FOR_ANCHORING_SERVICES_TO_END',
    label: 'Aguardando fim dos serviços de ancoragem',
    className: 'orange',
  },
  {
    status: 'DOCKING_PLACE_AVAILABLE',
    label: 'Local de atracação disponível',
    className: 'orange',
  },
  { status: 'DOCKING', label: 'Atracando', className: 'blue' },
  {
    status: 'IN_DOCKING_MANOEUVRE',
    label: 'Em manobra de atracação',
    className: 'blue',
  },
  {
    status: 'ANCHORING_SERVICES',
    label: 'Serviços no fundeio',
    className: 'blue',
  },

  { status: 'UNDOCKING', label: 'Desatracando', className: 'green-sea' },
  {
    status: 'IN_UNDOCKING_MANOEUVRE',
    label: 'Em manobra de desatracação',
    className: 'green-sea',
  },

  { status: 'CANCELED', label: 'Cancelada', className: 'red' },

  { status: 'OPERATION', label: 'Operação', className: 'operation' },
  { status: 'IN_OPERATION', label: 'Em operação', className: 'operation' },
  {
    status: 'OPERATION_FINISHED',
    label: 'Operação finalizada',
    className: 'operation',
  },

  { status: 'DONE', label: 'Concluído', className: 'green' },
  { status: 'DOCKED', label: 'Atracado', className: 'green' },
  { status: 'UNDOCKED', label: 'Desatracado', className: 'green' },
  {
    status: 'END_OF_UNDOCKING_MANOEUVRE',
    label: 'Fim da manobra de desatracação',
    className: 'green',
  },
];

export const manoeuvreStatusList = [
  { status: 'SCHEDULED', label: 'Prevista', className: 'orange' },
  { status: 'IN_PROGRESS', label: 'Em andamento', className: 'green' },
  { status: 'OVERDUE', label: 'Atrasada', className: 'red' },
  { status: 'CONFIRMED', label: 'Confirmada', className: 'blue' },
];

export const operationStatusList = [
  { status: 'SCHEDULED', label: 'Prevista', className: 'orange' },
  {
    status: 'WAITING_OPERATION',
    label: 'Aguardando operação',
    className: 'orange',
  },
  { status: 'PENDING', label: 'Confirmação pendente', className: 'orange' },
  {
    status: 'IN_PROGRESS',
    label: 'Em andamento',
    className: 'green-sea',
  },
  {
    status: 'IN_OPERATION',
    label: 'Operando',
    className: 'green-sea',
  },
  { status: 'OPERATION_FINISHED', label: 'Finalizada', className: 'blue' },
  { status: 'CANCELED', label: 'Cancelada', className: 'red' },
];

export const logItemsNames = [
  { statusId: 1, status: 'WAITING', label: 'Aguardando' },
  { statusId: 1, status: 'IN_PROGRESS', label: 'Em andamento' },
  { statusId: 1, status: 'DONE', label: 'Finalizada' },
  { statusId: 1, status: 'CANCELED', label: 'Cancelada' },
  { statusId: 1, status: 'REACTIVATED', label: 'Reativada' },
  {
    statusId: 5,
    status: 'WAITING_PRINCIPAL_DEFINITION',
    label: 'Aguardando definição dos principais',
  },
  {
    statusId: 5,
    status: 'ANCHORING_SERVICES',
    label: 'Em serviços no fundeio',
  },
  { statusId: 5, status: 'ANCHORED', label: 'Ancorada' },
  {
    statusId: 5,
    status: 'WAITING_DOCKING_PLACE_RELEASE',
    label: 'Aguardando liberação de berço',
  },
  {
    statusId: 2,
    status: 'IN_DOCKING_MANOEUVRE',
    label: 'Em manobra de atracação',
  },
  {
    statusId: 2,
    status: 'END_OF_DOCKING_MANOEUVRE',
    label: 'Fim da manobra de atracação',
  },
  { statusId: 2, status: 'DOCKING', label: 'Atracando' },
  { statusId: 2, status: 'DOCKED', label: 'Atracada' },
  {
    statusId: 3,
    status: 'IN_UNDOCKING_MANOEUVRE',
    label: 'Em manobra de desatracação',
  },
  {
    statusId: 3,
    status: 'END_OF_UNDOCKING_MANOEUVRE',
    label: 'Fim da manobra de desatracação',
  },
  { statusId: 3, status: 'UNDOCKING', label: 'Desatracando' },
  { statusId: 3, status: 'UNDOCKED', label: 'Desatracada' },
  { statusId: 4, status: 'DOCKING_PLACE_AVAILABLE', label: 'Berço disponível' },
  { statusId: 4, status: 'IN_OPERATION', label: 'Em operação' },
  { statusId: 4, status: 'OPERATION_FINISHED', label: 'Operação finalizada' },
];

export const staymentLogNames = [
  { status: 'STOPOVER', label: 'Estadia', id: 1 },
  { status: 'DOCKING', label: 'Atracação', id: 2 },
  { status: 'UNDOCKING', label: 'Desatracação', id: 3 },
  { status: 'OPERATION', label: 'Operações', id: 4 },
  { status: 'ANCHORING', label: 'Fundeio', id: 5 },
];

export const inspectionOperationList = [
  { id: 'ANVISA', name: 'Sim, Anvisa' },
  { id: 'CAPITANIA', name: 'Sim, Inspeção de Capitania' },
  { id: 'NO', name: 'Não' },
];

export const onDutyList = [
  { id: 'DAYTIME', name: 'Diurno' },
  { id: 'NIGHT', name: 'Noturno' },
];
export const onDutyRadioList = [
  { value: 'DAYTIME', label: 'Diurno' },
  { value: 'NIGHT', label: 'Noturno' },
];

export const breakdownOcurrenceTypeRadioList = [
  { label: 'Avaria', value: 'BREAKDOWN' },
  { label: 'Ocorrência', value: 'OCCURRENCE' },
];

export const purposeList = [
  { id: 'DOCK_IN_BERTH', name: 'Atracação em berço' },
  { id: 'ANCHORING_SERVICES', name: 'Serviços no fundeio' },
  { id: 'ANCHORING_WAIT', name: 'Aguardo no fundeio' },
];

export const operationDirectionList = [
  { id: 'LOADING', name: 'Carga' },
  { id: 'UNLOADING', name: 'Descarga' },
  { id: 'REMOVAL_ON_BOARD', name: 'Remoção a bordo' },
  { id: 'DEPARTURE', name: 'Safamento' },
];

export const shiftModeList = [
  { id: 'SIX', name: '6 horas' },
  { id: 'EIGHT', name: '8 horas' },
];

export const containerSizeList = [
  { id: 20, name: '20' },
  { id: 22, name: '22' },
  { id: 40, name: '40' },
];

export const compartmentTypeList = [
  { id: 'HOLD', name: 'Porão' },
  { id: 'DECK', name: 'Deck' },
  { id: 'TANK', name: 'Tanque' },
];

export const collapsePanelsListDocking = [
  'vessel',
  'stopover',
  'operation',
  'documentsConsents',
  'dockings',
  'financialOfficers',
  'operationsPanel',
  'generalData',
  'operationalOperations',
  'cargos',
];

export const chargeTypeList = [
  { id: 'SINGLE_FARE', name: 'Responsável financeiro' },
  { id: 'VESSEL', name: 'Embarcação estacionária' },
  { id: 'DOCKING', name: 'Atracação' },
];

export const listInvoicingStatus = [
  {
    id: 'UNBILLED',
    name: 'Não faturada',
    className: 'unbilled',
  },
  {
    id: 'BILLED',
    name: 'Faturada',
    className: 'billed',
  },
  {
    id: 'REBILLED',
    name: 'Refaturada',
    className: 'rebilled',
  },
];

export const pilotFunctionList = [
  { name: 'Praticante', id: 'PRACTITIONER' },
  { name: 'Prático', id: 'PILOT' },
];

type TableTypeList = {
  label: string;
  name: string;
  value: TableType;
  id: TableType;
  disabled: boolean;
  index: 0 | 1 | 2 | 3 | 5 | 9;
};

export const tableTypeList: TableTypeList[] = [
  {
    label: 'TABELA 1: Infraestrutura de Acesso Aquaviário',
    name: 'TABELA 1: Infraestrutura de Acesso Aquaviário',
    value: 'table_one',
    id: 'table_one',
    disabled: false,
    index: 1,
  },
  {
    label: 'TABELA 2: Instalações de Acostagem',
    name: 'TABELA 2: Instalações de Acostagem',
    value: 'table_two',
    id: 'table_two',
    disabled: false,
    index: 2,
  },
  {
    label: 'TABELA 3: Infraestrutura Operacional ou Terrestre',
    name: 'TABELA 3: Infraestrutura Operacional ou Terrestre',
    value: 'table_three',
    id: 'table_three',
    disabled: false,
    index: 3,
  },
  {
    label: 'TABELA 5: Utilização de Infraestrutura de Armazenagem',
    name: 'TABELA 5: Utilização de Infraestrutura de Armazenagem',
    value: 'table_five',
    id: 'table_five',
    disabled: false,
    index: 5,
  },
  {
    label: 'TABELA 9: Complementares',
    name: 'TABELA 9: Complementares',
    value: 'table_nine',
    id: 'table_nine',
    disabled: false,
    index: 9,
  },
];
export const chassisStatus = [
  { value: 'PREDICTED', label: 'Previsto', name: 'Previsto', id: 'PREDICTED' },
  {
    value: 'IN_WAREHOUSING',
    label: 'No pátio',
    name: 'No pátio',
    id: 'IN_WAREHOUSING',
  },
  {
    value: 'SEGREGATED',
    label: 'Segregado',
    name: 'Segregado',
    id: 'SEGREGATED',
  },
  { value: 'SHIPPED', label: 'Embarcado', name: 'Embarcado', id: 'SHIPPED' },
  {
    value: 'UNSHIPPED',
    label: 'Desembarcado',
    name: 'Desembarcado',
    id: 'UNSHIPPED',
  },
  {
    value: 'DELIVERED',
    label: 'Entregue',
    name: 'Entregue',
    id: 'DELIVERED',
  },
  {
    value: 'CANCELED',
    label: 'Cancelado',
    name: 'Cancelado',
    id: 'CANCELED',
  },
];

export const warehousingImportStatus = [
  {
    value: 'PENDING_LOAD_PRESENCE',
    label: 'Presença de carga pendente',
    name: 'Presença de carga pendente',
    id: 0,
    backgroundColor: '#CCDDE9',
    color: '#5F7B91',
  },
  {
    value: 'DONE_LOAD_PRESENCE',
    label: 'Presença de carga efetuada',
    name: 'Presença de carga efetuada',
    id: 1,
    backgroundColor: '#A0E6F1',
    color: '#0096AB',
  },
  {
    value: 'PENDING_RELEASE',
    label: 'Liberação pendente',
    name: 'Liberação pendente',
    id: 2,
    backgroundColor: '#FFC069',
    color: '#D46B08',
  },
  {
    value: 'RELEASED_LOAD',
    label: 'Liberada',
    name: 'Liberada',
    id: 3,
    backgroundColor: '#90F097',
    color: '#1AAA24',
  },
  {
    value: 'DELIVERED_LOAD',
    label: 'Entregue',
    name: 'Entregue',
    id: 4,
    backgroundColor: '#8FBFD6',
    color: '#1D6181',
  },
];

export const portalStatus = [
  {
    value: 'RECEIVED',
    label: 'Recepcionada',
    name: 'Recepcionada',
    id: 'RECEIVED',
  },
  {
    value: 'NOT_RECEIVED',
    label: 'Não recepcionada',
    name: 'Não recepcionada',
    id: 'NOT_RECEIVED',
  },
  {
    value: 'OUTDATED',
    label: 'Desatualizada',
    name: 'Desatualizada',
    id: 'OUTDATED',
  },
];
export const chargeStatusList = [
  {
    id: 'INVOICING',
    name: 'Faturando',
    className: 'invoicing',
  },
  {
    id: 'BILLING',
    name: 'A faturar',
    className: 'billing',
  },
  {
    id: 'BILLED',
    name: 'Faturada',
    className: 'billed',
  },
  {
    id: 'REBILLED',
    name: 'Refaturada',
    className: 'rebilled',
  },
  {
    id: 'REVERSED',
    name: 'Estornado',
    className: 'reversed',
  },
];

export const csmDirectionList = [
  { id: 'DISPATCH', name: 'Expedição' },
  { id: 'RECEIPT', name: 'Recebimento' },
];

export const statusStorageList = [
  {
    id: 'PENDING',
    label: 'confirmação pendente',
    className: 'storage-status pending',
  },
  {
    id: 'CONFIRMED',
    label: 'confirmado',
    className: 'storage-status confirmed',
  },
  { id: 'REJECTED', label: 'rejeitado', className: 'storage-status rejected' },
  {
    id: 'NEW_SCHEDULE',
    label: 'nova previsão',
    className: 'storage-status new-schedule',
  },
];

export const portAreaList = [
  { id: 'INTERNAL', name: 'Interna' },
  { id: 'EXTERNAL', name: 'Externa' },
];

export const BLCards = [
  { folderName: 'CIN', title: 'CIN' },
  { folderName: 'DI', title: 'DIs' },
  { folderName: 'NFE', title: 'NOTAS FISCAIS' },
];

export const CECards = [
  { folderName: 'CIN', title: 'CIN' },
  { folderName: 'CE', title: 'CEs MERCANTEs' },
  { folderName: 'BL', title: 'BLs originais e assinados' },
  { folderName: 'DI', title: 'DIs' },
  { folderName: 'DMI', title: 'DMIs' },
  { folderName: 'NFE', title: 'NOTAS FISCAIS' },
];

export const DUEStatusList = [
  {
    id: 'DUE_NOT_RELEASED',
    label: 'DUE não liberada',
    className: '',
  },
  {
    id: 'DUE_RELEASED',
    label: 'DUE liberada',
    className: 'due-status released',
  },
  {
    id: 'DUE_DELIVERED',
    label: 'DUE entregue',
    className: 'due-status delivered',
  },
  {
    id: 'DUE_OUTDATED',
    label: 'DUE desatualizada',
    className: 'due-status outdated',
  },
];

export const VehiclesWarehousingOperatorsCompanies = [
  'proporto',
  'nexus',
  'tegma',
];

export const ANTAQRequiredFields = [
  { item: 'navigation_type', translatedItem: 'Tipo de navegação' },
  {
    item: 'expected_berthing',
    translatedItem: 'Previsão de Chegada da Atracação',
  },
  { item: 'shipping_agency__cnpj', translatedItem: 'Agência da Escala' },
  {
    item: 'charterer__identifier',
    translatedItem: 'Armador Afretador da Escala',
  },
];
