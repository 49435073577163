import { Table } from 'antd';

import { SlimTable } from '../../../../components';
import {
  AllTableItemsType,
  BaseTableItemType,
} from '../../../../typesFinancial';
import { formatNumberToLocale } from '../../../../utils/utils';
import { fareItemsTableColumns } from './fareItemsTableColumns';

type FareItemsTableProps = {
  fareItems?: AllTableItemsType[];
  onEdit?: (item: BaseTableItemType) => void;
  tableIndex?: 1 | 2 | 3 | 5 | 9;
};

export function FareItemsTable(props: FareItemsTableProps) {
  const { fareItems, onEdit, tableIndex } = props;

  function getTableSummary() {
    let totalValue = (fareItems || [])
      .filter((item) => item.name !== 'Descontos')
      .reduce((sum, current) => sum + (current.total_value || 0), 0);

    const discount = (fareItems || []).find(
      (item) => item.name === 'Descontos'
    );
    if (discount && discount.value) {
      totalValue -= discount.value;
    }

    return (
      <Table.Summary fixed>
        <Table.Summary.Row key="row_0">
          <Table.Summary.Cell index={0} key={0} align="left">
            TOTAIS:
            {fareItems && fareItems?.length > 0 ? fareItems?.length || 0 : 0}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} key={1} colSpan={2} align="right">
            <span style={{ fontFamily: 'Consolas', fontWeight: '400' }}>
              {formatNumberToLocale(totalValue, true)}
            </span>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  }

  return (
    <SlimTable
      rowKey="id"
      columns={fareItemsTableColumns(onEdit, tableIndex)}
      dataSource={fareItems || []}
      className="read-only"
      pagination={false}
      summary={() => getTableSummary()}
    />
  );
}
