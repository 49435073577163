import { Row, message } from 'antd';
import FormList from 'antd/lib/form/FormList';
import { debounce } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  Form,
  GenericDrawerHeader,
  ModalBlockNavigation,
  FormItemSelect,
  Button,
  Select,
} from '../../../components';
import {
  useCreateChargeMutation,
  useGetPriceTablesQuery,
} from '../../../services/chargeApi';
import { useGetCompaniesQuery } from '../../../services/companyApi';
import { useGetVesselQuery } from '../../../services/vesselApi';
import { Stopover as StopoverType } from '../../../types';
import { ChargeToFormType } from '../../../typesFinancial';
import { chargeTypeList, tableTypeList } from '../../../utils/lists';
import { formatChargeToSave } from './formatters';
import { TableCollapse, TableTypesType } from './tableCollapse';

type ChargeFormProps = {
  selectedStopover: StopoverType;
  setHasEdited: (hasEdited: boolean) => void;
  blockNavigate: boolean;
  setBlockNavigate: (blockNavigate: boolean) => void;
  onCloseDrawer: () => void;
  onCloseBlockNavigation: () => void;
  setSelectedStopover: Dispatch<SetStateAction<StopoverType>>;
};

export type TableTypeListType = {
  label: string;
  value: string;
  disabled: boolean;
};

export function ChargeForm(props: ChargeFormProps) {
  const {
    selectedStopover,
    setHasEdited,
    onCloseDrawer,
    onCloseBlockNavigation,
    setSelectedStopover,
    blockNavigate,
    setBlockNavigate,
  } = props;

  const [form] = Form.useForm();

  const [chargeType, setChargeType] = useState<
    'DOCKING' | 'SINGLE_FARE' | 'VESSEL'
  >();

  const [
    createCharge,
    { isLoading: isLoadingCreateCharge, isSuccess: isSuccessCreateCharge },
  ] = useCreateChargeMutation();

  async function handleFormSubmit() {
    form.validateFields().then(async (values: ChargeToFormType) => {
      let hasError = false;
      values.tables?.forEach((table) => {
        if (table.table_type === 'table_five') {
          hasError =
            table.items?.some(
              (item) =>
                item.cargo_arrival_date &&
                item.cargo_arrival_date !== null &&
                item.cargo_departure_date &&
                item.cargo_departure_date !== null &&
                item.cargo_arrival_date > item.cargo_departure_date
            ) || false;
          if (hasError) {
            message.error(
              'Início de armazenamento não pode ser maior que o fim do armazenamento de um item'
            );
          }
          hasError =
            table.items?.some(
              (item) => !item.cargo_arrival_date && item.cargo_departure_date
            ) || false;
          if (hasError) {
            message.error(
              'Não é possível informar o fim do armazenamento sem informar o início'
            );
          }
        }
      });
      if (!hasError) {
        const response = await createCharge(formatChargeToSave(values));
        if ('data' in response) {
          setSelectedStopover(response.data);
        }
      }
    });
  }

  useEffect(() => {
    if (isSuccessCreateCharge) {
      form.resetFields();
      onCloseDrawer();
    }
  }, [isSuccessCreateCharge]);

  function onSelectChargeType(value: 'DOCKING' | 'SINGLE_FARE' | 'VESSEL') {
    setChargeType(value);
  }

  const [unavailableTableTypes, setUnavailableTableTypes] = useState<string[]>(
    []
  );

  function onSelectTableType(value: TableTypesType) {
    const unavailable: string[] = [];
    form.getFieldsValue().tables.forEach((table: { table_type: string }) => {
      unavailable.push(table.table_type);
    });
    setUnavailableTableTypes([...unavailable, value]);
  }

  const [searchCompanyName, setSearchCompanyName] = useState('');
  const [searchVesselName, setSearchVesselName] = useState('');
  const { data: filteredCompanies, isFetching: isLoadingCompanies } =
    useGetCompaniesQuery({
      page_size: 100,
      name_or_cnpj: searchCompanyName,
    });

  const { data: foundVessel, isLoading: isLoadingVessels } = useGetVesselQuery({
    name: searchVesselName,
  });

  function onSearchCompany(evt: string) {
    setSearchCompanyName(evt);
  }
  function onSearchVessel(evt: string) {
    setSearchVesselName(evt);
  }

  useEffect(() => {
    const selectedTablesArray = form
      .getFieldsValue()
      .tables?.map((table: any) => Object.keys(table))
      .flat();
    if (selectedTablesArray) {
      setUnavailableTableTypes(selectedTablesArray);
    }
  }, []);

  function companyRenderer(option: Record<string, any>) {
    return (
      <Select.Option key={option.id} value={option.id}>
        {`${option.cnpj && `${option.cnpj} - `} ${option.name}`}
      </Select.Option>
    );
  }
  function vesselOptionRenderer(option: Record<string, any>) {
    return (
      <Select.Option key={option.key} value={option.id}>
        {`${option.imo} - ${option.name}`}
      </Select.Option>
    );
  }
  return (
    <Form
      form={form}
      autoComplete="off"
      initialValues={selectedStopover}
      layout="vertical"
      name="create_charge_form"
      id="create_charge_form"
      onFinish={handleFormSubmit}
      onValuesChange={() => {
        setHasEdited(true);
      }}
    >
      <ModalBlockNavigation
        name="create_charge_form"
        key="create_charge_form"
        setHasEdited={setHasEdited}
        showModal={blockNavigate}
        setBlockNavigate={setBlockNavigate}
        setShowModal={setBlockNavigate}
        afterCloseModal={onCloseBlockNavigation}
      />
      <GenericDrawerHeader
        onBack={() => onCloseDrawer && onCloseDrawer()}
        title={
          <span style={{ fontWeight: '700', fontSize: '16px' }}>
            Adicionar tarifa avulsa
          </span>
        }
        showBackButton
        formName="create_charge_form"
        disabledSaveButton={isLoadingCreateCharge}
        isLoadingSave={isLoadingCreateCharge}
      />
      <div style={{ margin: '24px 16px' }}>
        <Row gutter={24}>
          <FormItemSelect
            colSpan={12}
            name={['company', 'id']}
            label="Responsável Financeiro"
            allowClear
            onSearch={debounce(onSearchCompany, 300)}
            dataList={filteredCompanies?.results
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            optionRenderer={companyRenderer}
            showSearch
            isLoading={isLoadingCompanies}
            required
          />
          <FormItemSelect
            colSpan={12}
            name="charge_type"
            label="Tipo de faturamento"
            dataList={chargeTypeList.filter(
              (charge) => charge.id !== 'DOCKING'
            )}
            onSelect={(value) => onSelectChargeType(value)}
            allowClear
            required
          />
        </Row>
        {chargeType === 'VESSEL' && (
          <Row gutter={24}>
            <FormItemSelect
              name={['vessel', 'id']}
              label="Embarcação"
              allowClear
              optionRenderer={vesselOptionRenderer}
              showSearch
              onSearch={debounce(onSearchVessel, 300)}
              dataList={foundVessel?.results
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))}
              isLoading={isLoadingVessels}
            />
          </Row>
        )}
        <FormList name="tables">
          {(tables, { add: addTable }) => {
            return (
              <div>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => addTable({})}
                  disabled={form.getFieldValue('tables')?.length >= 3}
                  style={{ marginBottom: '16px' }}
                >
                  Adicionar tabela
                </Button>

                {tables.map((table, index) => {
                  return (
                    <TableCollapse
                      isAddCharge
                      key={index}
                      tableIndex={index}
                      form={form}
                      onSelectTableType={onSelectTableType}
                      availableTableTypes={tableTypeList.map((tableType) => {
                        if (unavailableTableTypes?.includes(tableType.value)) {
                          return {
                            ...tableType,
                            disabled: true,
                          };
                        }
                        return {
                          ...tableType,
                        };
                      })}
                    />
                  );
                })}
              </div>
            );
          }}
        </FormList>
      </div>
    </Form>
  );
}
