import { ColumnsType } from 'antd/lib/table';

import { Checkbox } from '../../../../../components';
import { formatNumberToLocale } from '../../../../../utils/utils';

export function columns(
  onSelectItem: (e: boolean, record: any) => void,
  hasCheckboxColumn: boolean,
  isVehicleManifest: boolean,
  checkedAll: boolean,
  setCheckedAllNcmItems: (e: boolean) => void
): ColumnsType<Record<string, any>> {
  const items: ColumnsType<Record<string, any>> = [];
  if (hasCheckboxColumn) {
    items.push({
      title: () => {
        if (isVehicleManifest) {
          return (
            <Checkbox
              checked={checkedAll}
              onChange={(e) => {
                setCheckedAllNcmItems(e.target.checked);
              }}
            />
          );
        }
        return <div />;
      },
      width: '5%',
      dataIndex: 'cargo_assigned',
      render(_value, record) {
        if (checkedAll) {
          return <Checkbox checked disabled />;
        }
        return (
          <div>
            <Checkbox
              onChange={(e) => onSelectItem(e.target.checked, record)}
            />
          </div>
        );
      },
    });
  }
  items.push(
    {
      title: 'CE Mercante',
      dataIndex: 'code',
      width: '19%',
    },
    {
      title: 'NCM',
      dataIndex: 'ncm_code',
      width: hasCheckboxColumn ? '10%' : '13%',
    },
    {
      title: 'Armazenamento',
      dataIndex: ['storage_terminal', 'name'],
      width: '22%',
    },
    {
      title: 'Tipo da mercadoria',
      dataIndex: ['cargo_type', 'name'],
      width: '15%',
    },
    {
      title: isVehicleManifest ? 'Chassis' : 'Volume',
      dataIndex: isVehicleManifest ? 'chassis' : 'volume',
      width: '5%',
    }
  );

  if (!isVehicleManifest) {
    items.push({
      title: 'Peso total',
      dataIndex: 'weight',
      width: hasCheckboxColumn ? '19%' : '20%',
      align: 'right',
      render(value) {
        return <span>{formatNumberToLocale(value)} ton</span>;
      },
    });
  }
  return items;
}
